import axios from 'axios';

const setFacebookData = {

    methods: {
        /**
         * Updates the facebookData col in the database.
         * @param {data} str The stingified json
         * @param {partial} str Optional the .metric that's specifically being set
         * @param {clientSlug} str Optional, normally uses the getClientSlug function
         */
        setFacebookData(data, partial = false, clientSlug = '') {
            const params = new URLSearchParams();
            if (clientSlug) {
                params.append('clientSlug', clientSlug);
            } else {
                params.append('clientSlug', this.getClientSlug);
            }
            if (partial) {
                // If only 1 metric is being updated use the setClientGoogleMetric function
                let theValue = { 'metricname': partial, 'data': data }
				this.$store.commit("client/setClientFacebookMetric", theValue)
            } else {
                // The whole google is updating so do the whole axois bit.
                params.append('data', data);
                // console.log(params);
                axios.post('/api/clients/facebookData/updateFacebookData', params, {
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                    },
                })
                .then(async () => {
                    console.log('Facebook Data set');
                })
                .catch((err) => console.error(err.response));
            }
        },
    },
};

export default setFacebookData;
