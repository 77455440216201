const formatPercentage = {
	methods: {
		formatPercentage(x) {
			if (Number.isNaN(x) || isNaN(x) || x === '--') {
				// If not a number return nothing
				return '--';
			} else if (x === Infinity || x === 'Infinity') {
				// If infinity return a nicely formatted bit
				return 'Infinity';
			} else {
				x = parseFloat(x);
				// If it's a normal number it needs formatting
				// - Over 9999% needs commas
				// - no trailing zeros on the decimal places
				// - 0dp for over 99.99% (and their negative equivalents)
				// - 1dp for 10 - 99.99% (and their negative equivalents)
				// - 2dp for 0 - 9.99%   (and their negative equivalents)
				if (x > 9999) {
					// Over 9999
					return parseFloat(x).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '%';
				} else if ((x >= 100)) {
					// 100 to 9999
					return parseFloat(x).toFixed(0) + '%';
				} else if ((x >= 10 && x < 100) || (x < -10 && x > -100)) {
					// 10 to 100 or -100 to -10
					return parseFloat(x).toFixed(1).toString().replace('.0', '') + '%';
				} else if (x === 0 || x === 0.0) {
					// Zero
					return '0%';
				} else {
					// -10 to 10
					return parseFloat(x).toFixed(2).toString().replace('.00', '').replace(/0$/, '') + '%';
				}
			}
		},
	},
}

export default formatPercentage;
