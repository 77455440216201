import axios from 'axios';
export default {
    async checkAuthenticatedGoogleAccessToken(context) {
        try {
            const response = await axios.get(`/api/google/refreshAccessToken?slug=${context.getters.getClientSlug}`);
            context.commit('setSocial', {prop: 'tokens/google/accessToken', val: response.data});
        } catch (error) {
            console.log('checkAuthenticatedGoogleAccessToken catch error', error);
        }
    }
}