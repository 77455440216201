export default {
	/**
	 * Realistically, we should avoid using this getter as property names may change e.g.
	 * getClientData.x could become getClientData.y thus requiring an update of all getUserData dot notation references
	 *
	 * It's here just in case!
	 * @returns The entirety of the client's data
	 */
	getClientData: (state) => {
		return state.clientData;
	},
	/**
	 * @returns The client's content tree data (Nodes & Links)
	 */
	getClientContentTree: (state) => {
		return state.clientContentTree;
	},
	/**
	 * @returns The client's content tree data (Nodes & Links)
	 */
	getClientContentTreeReach: (state) => {
		return state.rootNodeReach;
	},
	/**
	 * @returns The client's name from the client data store
	 */
	getClientName: (state) => {
		return state.clientData.clientName;
	},
	/**
	 * @param {Object} state vuex strategy
	 * @returns The client's slug from the client data store
	 */
	getClientSlug: (state) => {
		return state.clientData.slug;
	},
	/**
	 * Returns field values from the current Node top populate the Node edit
	 * and node overview menus. Specific method of usage can be found in AddNodeForm.vue.
	 * Uses string interpolation to select the fieldgroup and the field within.
	 * @param {String} fieldgroup The fieldgroup to be selected
	 * @param {String} field The field to be selected within the fieldgroup
	 * @returns The value of the field
	 */
	getNodeFieldValue: (state) => (fieldGroup, fieldTitle) => {

		return state.currentNode.fieldGroups[fieldGroup].fields[fieldTitle].value;
	},
	getNodeBlogContentValue: (state) => (fieldTitle) => {
		return state.currentNodeBlogContent[fieldTitle]
	},
	getHasCurrentNodeBlogContentBeenEdited: (state) => {
		return state.hasCurrentNodeBlogContentBeenEdited;
	},
	/**
	 *
	 * @returns All of the currently selected node's data
	 */
	getCurrentNode: (state) => {
		return state.currentNode;
	},
	getComment: (state) => {
		return state.comment;
	},
	getMediaList: (state) => {
		return state.mediaList;
	},
	getCancelComment: (state) => {
		return state.cancelComment;
	},
	getCurrentNodeBlogContent: (state) => {
		return state.currentNodeBlogContent;
	},
	getNodeBlogContentHTML: (state) => {
		return state.currentNodeBlogContent.blogHTML;
	},
	getClientNodeMediaTempStorage: (state) => {
		return state.clientNodeMediaTempStorage;
	},
	getClientNodeComments: (state) => {
		return state.clientNodeComments;
	},
	getHasCommentsBeenUpdated(state){
		return state.hasCommentsBeenUpdated;
	},
	getHighlightedNode: (state) => {
		return state.highlightedNode;
	},
	getClientUsers: (state) => {
		// If there isn't data, we send an empty array.
		return state.clientData.Users || [];
	},
	getContentTreeFilters: (state) => {
		return state.contentTreeFilters;
	},
	getClientMainContact: (state) => {
		return state.clientData.mainContact;
	},
	getClientAvatarUrl: (state) => {
		return state.clientData.avatarUrl;
	},
	getClientGoogleData: (state) => {
		return state.clientData.googleData;
	},
	getClientFacebookData: (state) => {
		return state.clientData.facebookData;
	},
	hasCurrentNodeBeenEdited: (state) => {
		return state.hasCurrentNodeBeenEdited;
	},
	getClientPagesLoaded: (state) => {
		return state.clientPagesLoaded;
	},
	getHidePageLoader: (state) => {
		return state.loaders.hidePageLoader
	},
	getHeaderGraphBlockReset: (state) => {
		return state.HeaderGraphBlockReset
	},
	getHeaderGraphBlockReset2: (state) => {
		return state.HeaderGraphBlockReset2
	},
	getHeaderGraphBlockReset3: (state) => {
		return state.HeaderGraphBlockReset3
	},
	getMentions: (state) => {
		return state.mentions;
	},
	getComments: (state) => {
		return state.clientNodeComments;
	},
	getOpenKeywordPlanner: (state) => {
		return state.openKeywordPlanner;
	},
	getOpenBlogEditor: (state) => {
		return state.openBlogEditor;
	},
	getOpenBlogEditorAddNodeForm: (state) => {
		return state.openBlogEditorAddNodeForm;
	},
	getCreateComment:(state) => {
		return state.createComment;
	},
	getAddNodeFormOpen:(state) => {
		return state.addNodeFormOpen;
	},
	getNodeBlogContentFeaturedImage:(state) => {
		return state.currentNodeBlogContent.featuredImageURL;
	},
	getOpenCommentPanel:(state) => {
		return state.openCommentPanel;
	},
	getEditSingleComment:(state) => {
		return state.editComment;
	},
	getReplyComment:(state) => {
		return state.replyComment;
	},
	getTaggedUser:(state) => {
		return state.taggedUser;
	},
	getKeywordPlanner:(state) => {
		console.log('getKeywordPlanner');
		return state.keywordPlanner;
	},
	getSocial:(state) => {
		return state.social;
	},
	getScheduledBlogPosts: (state) => {
		return state.scheduledBlogPosts;
	},
	getBlogModalOpen: (state) => {
		return state.blogModalOpen
	},
	getListView: (state) => {
		return state.listView;
	},
	getLockContentTree: (state) => {
		return state.lockContentTree;
	},
	getActiveNode: (state) => {
		return state.activeNode;
	},
	getChangedNode: (state) => {
		return state.changedNode;
	},
	getSaveContentTree: (state) => {
		return state.changedNode;
	},
	getComponents: (state) => {
		return state.components;
	},
	getWaitingForPostToPublish: (state) => {
		return state.waitingForPostToPublish;
	},
	getLoopThatChecksIfPostPublishedRunning: (state) => {
		return state.loopThatChecksIfPostPublishedRunning;
	},
	getIsCreatingNewNode: (state) => {
		return state.isCreatingNewNode;
	},
	getIsCreatingNewNodeToAddToDB: (state) => {
		return state.isCreatingNewNodeToAddToDB
	},
	getAllNodeReachStats: (state) => {
		const nodes = state.clientContentTree.nodes;
		const filteredNodes = nodes.filter(node => node.id !== 'currentNode');
		return filteredNodes.map(node => ({
			id: node.id, 
			reach: node.reach, 
			accumulativeReach : node.accumulativeReach ? node.accumulativeReach : 0, 
			historicalAccumulativeReach: node.historicalAccumulativeReach ? node.historicalAccumulativeReach : []
		}));
	},
	getZoomToFitContentTree: (state) => {
		return state.zoomToFitContentTree
	},
	getContentTreeSiblingIds: (state) => {
		return state.contentTreeSiblingIds;
	},
	getNodesSiblingData: (state) => {
		let arr = [];
		const links = state.clientContentTree.links.filter(link => link.sibling === true);
		links.forEach(link => {
			if (link.source !== link.target) {
				let index = arr.findIndex(item => item.id === link.source);
				if (index !== -1) {
					arr[index].siblings.push(link.target);
				} else {
					arr.push({id: link.source, siblings: [link.target]});
				}
			}
		});
		return arr;
	},
	getNodeWasOrIsPosting: (state) => {
		return state.nodeWasOrIsPosting
	},
	getAIPopUpFields: (state) => {
		return state.AIPopUpFields;
	},
	getRemoveRowLoader: (state) => {
		return state.loaders.removeRowLoader;
	},
	getLoaderStatus: (state) => {
		return state.loaders;
	},
	getCloseNodeOverview: (state) => {
		return state.closeNodeOverview;
	},
	getAuthUsername: (state) => {
		return state.authUsername;
	},
	getUserIsRequiredToReloadPage: (state) => {
		return state.userIsRequiredToReloadPage;
	},
	getOldNodeId: (state) => {
		return state.oldNodeId;
	},
	getZoomingAnimationOnTreeActive: (state) => {
		return state.zoomingAnimationOnTreeActive;
	},
	getGoogleAccessToken: (state) => {
		return state.googleAccessToken;
	},
	getLockListCategories: (state) => {
		return state.lockListCategories;
	},
	getImportModalOpen: (state) => {
		return state.importModalOpen;
	},
	getImportedContentTree: (state) => {
		return state.importedContentTree;
	},
	getACFPickerData: (state) => {
		return state.acfPickerData;
	},
	getContentTreeWasResized: (state) => {
		return state.contentTreeWasResized;
	},
	getReportProblem: (state) => {
		return state.reportProblem;
	},
	getTaggedUsers:(state) => {
		return state.taggedUsers;
	},
	getBlogContentIsInDB:(state) => {
		return state.blogContentIsInDB;
	},
	getMutliSelectNodeCategories:(state) => {
		return state.mutliSelectNodeCategories;
	},
	getReloadContentTree:(state) => {
		return state.reloadContentTree;
	},
	getUserOpenedBlogOrSocialEditor:(state) => {
		return state.userOpenedBlogOrSocialEditor;
	},
	getShowEditor:(state) => {
		return state.showEditor;
	},
	getOpenaddNodeClickTitle:(state) => {
		return state.openaddNodeClickTitle;
	},
};
