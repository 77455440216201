import { createStore } from 'vuex';

// import nodeTreeModule from './modules/node-tree';
import authModule from './modules/auth';
import clientModule from './modules/client';
import userModule from './modules/user';
import viewsModule from './modules/views';
import rolesModule from './modules/roles';
import toolsModule from './modules/tools';
import rootActions from './actions';
import rootMutations from './mutations';
import rootGetters from './getters';

const store = createStore({
	strict: true,
	modules: {
		// nodeTree: nodeTreeModule,
		auth: authModule,
		client: clientModule,
		user: userModule,
		roles: rolesModule,
		views: viewsModule,
		tools: toolsModule
	},
	state() {
		return {
			route: {},
		};
	},
	getters: rootGetters,
	mutations: rootMutations,
	actions: rootActions,
});

export default store;
