import axios from 'axios';
import {conversionRate} from '../../../../../mixins/conversionRateFunc';
import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {

    async analyticsPageSessionBlockInit(context){
        try{
                // API urls
            
            
            const metricsURL = `/api/google/metric?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&metrics=`;
            const metricClean = 'screenPageViewsPerSession';
            const metricPvps = `${metricClean}`;
            let createNewMetric = false;
            let updatedGoogleData;

            // Work out the time since the last update
            let timeNow = Date.now();
            // If this is the first time it's been loaded there's nothing in the database so:
            // 1. set the time higher to ensure the API data is collected
            let timeUpdated = dayMilliseconds + 1;
            if (context.getters.getClientGoogleData.metrics[metricClean]) {
            // 2. If the data does exist replace the time updated & set the initial variables from the DB
            timeUpdated = context.getters.getClientGoogleData.metrics[metricClean].updated;
            // Set the initial variables from the DB
            context.commit('setComponents', {prop: 'analytics/PagesSessionBlock/pagesSessionVal', val: parseFloat(context.getters.getClientGoogleData.metrics[metricClean].current).toFixed(2)});
            context.commit('setComponents', {prop: 'analytics/PagesSessionBlock/pagesSessionComparison', val: (((parseFloat(context.getters.getClientGoogleData.metrics[metricClean].current) / parseFloat(context.getters.getClientGoogleData.metrics[metricClean].previous)) * 100) - 100).toFixed(1)});
            // Set the class based on the rate
            context.commit('setComponents', {prop: 'analytics/PagesSessionBlock/pagesSessionClass', val: conversionRate(context.getters.getComponents.analytics.PagesSessionBlock.pagesSessionComparison)});
            } else {
            // And if it doesn't add a placeholder for the new metric
            createNewMetric = true
            }
            let timeSince = timeNow - timeUpdated;

            // If the data hasn't been updated for over a day update from the API
            if (timeSince > dayMilliseconds) {

                const response = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`);

                /*
                    * The data is over a day old, so update from the API and save the updated information to the database.
                    */
                console.log('updated PVPS over a day ago');
                updatedGoogleData = response.data;
                if (createNewMetric) {
                    updatedGoogleData.metrics[metricClean] = {}
                }
                /*
                * connect to the API and get the data for the pages per session this month, then compare with last month.
                */
                let currentPvpsQS = `${metricPvps}&startDate=28daysAgo&endDate=1daysAgo`;
                const res = await axios.get(metricsURL + currentPvpsQS);
                const data = res.data.data.rows[0].metricValues[0].value;

                context.commit('setComponents', {prop: 'analytics/PagesSessionBlock/pagesSessionVal', val: parseFloat(data).toFixed(2)});
                updatedGoogleData.metrics[metricClean].current = data
    
                // Then compare to previous 30 days
                let prevPvpsQS = `${metricPvps}&startDate=56daysAgo&endDate=29daysAgo`;
                const res2 = await axios.get(metricsURL + prevPvpsQS);
                const data2 = res2.data.data.rows[0].metricValues[0].value;
                
                context.commit('setComponents', {prop: 'analytics/PagesSessionBlock/pagesSessionComparison', val: (((parseFloat(context.getters.getComponents.analytics.PagesSessionBlock.pagesSessionVal) / data2) * 100) - 100).toFixed(1)});
                updatedGoogleData.metrics[metricClean].previous = data2
                updatedGoogleData.metrics[metricClean].updated = Date.now()
                await setGoogleData(updatedGoogleData.metrics[metricClean], metricClean, context.getters.getClientSlug)
                // Set the class based on the rate
                context.commit('setComponents', {prop: 'analytics/PagesSessionBlock/pagesSessionClass', val: conversionRate(context.getters.getComponents.analytics.PagesSessionBlock.pagesSessionComparison)});
            }

        }catch(error){
            console.log('page session block:', error);
        }
    }
}