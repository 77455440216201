import headerGraphBlock from './headerGraphBlock';
import facebookConversionsBlock from './facebookConversionsBlock';
import conversionsBlock from './conversionsBlock';
import facebookImpressionsBlockInit from './facebookImpressionsBlock';
import paidSocialClicksBlockInit from './paidSocialClicksBlock';

export default {
    ...headerGraphBlock,
    ...facebookConversionsBlock,
    ...conversionsBlock,
    ...facebookImpressionsBlockInit,
    ...paidSocialClicksBlockInit,
}