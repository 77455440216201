export default {
	/**
	 * Called by the fetchUserData action on a resolved API response.
	 * Commits the user data to the store.
	 *
	 * @param {Object} state vuex state
	 * @param {Object} data user data received from the API
	 */
	setIsOpenInfoModal(state, data) {
		state.infoModal.isOpen = data;
	},
	setCurrentStepInfoModal(state, data) {
		state.infoModal.currentStep = data;
	},
	setStepsInfoModal(state, data) {
		state.infoModal.steps = data;
	},
	setIsOpenMediaModal(state, data) {
		state.mediaModal.isOpen = data;
	},
	setPushSelectedMediaMediaModal(state, data) {
		state.mediaModal.selectedMedia.push(data);
	},
	setRemoveSelectedMediaMediaModal(state, data) {
		state.mediaModal.selectedMedia = state.mediaModal.selectedMedia.filter(media => media !== data);
	},
	setSelectedMediaMediaModal(state, data){
		state.mediaModal.selectedMedia = data;
	},
	setDropedFiles(state, data) {
		state.infoModal.droppedFiles = data;
	},
	setReloadMediaList(state){
		state.mediaList.reload = state.mediaList.reload + 1;
	},
	setAddMedia(state, data){
		state.mediaModal.addMedia = data;
		state.mediaModal.isOpen = false;
	},
	setMediaModalCurrentComponent(state, data){
		state.mediaModal.currentComponent = data;
	},
	setClientSlug(state, data){
		state.clientSlug = data;
	},
	setNotification(state, data){
		state.notification = data;
	},
	setHidePageLoader(state, value) {
		state.loaders.hidePageLoader = value;
	},
};
