export default {
	getInfoModal: (state) => {
		return state.infoModal;
	},
	getMediaModal: (state) => {
		return state.mediaModal;
	},
	getSelectedMediaMediaModal: (state) => {
		return state.mediaModal.selectedMedia;
	},
	getDroppedFiles: (state) => {
		return state.mediaModal.droppedFiles;
	},
	getReloadMediaList: (state) => {
		return state.mediaList.reload;
	},
	getAddMedia: (state) => {
		return state.mediaModal.addMedia;
	},
	getMediaModalCurrentComponent: (state) => {
		return state.mediaModal.currentComponent;
	},
	getClientSlug: (state) => {
		return state.clientSlug;
	},
	getNotification:(state) => {
		return state.notification;
	},
	getHidePageLoader: (state) => {
		return state.loaders.hidePageLoader
	},
};
