
import axios from 'axios';
const domainURL = process.env.DOMAIN_URL;
export default {

    async downloadBlog(context){
		const nodeId = context.getters.getCurrentNode.id;
		console.log('downloadBlog:',domainURL)
		try {
			const response = await axios.get(`/api/clients/downloadBlog/${nodeId}/${context.getters.getClientSlug}`, { responseType: 'blob' });
			// Create a blob from the response
			const blob = new Blob([response.data], { type: 'application/zip' });
			// Create a blob URL
			const url = window.URL.createObjectURL(blob);
			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'blog_export.zip');
			// Append the link to the body
			document.body.appendChild(link);
			// Simulate a click
			link.click();
			// Remove the link from the body
			document.body.removeChild(link);
		  } catch (error) {
			console.log('downloadBlog error', error);
		  }
	},

}