import axios from "axios"
// import store from '../..';
import router from "@/router"

export default {
	/**
	 * Grabs all user data from the database and populates it into the vuex store for use within the app.
	 *
	 * @param {Object} context Vuex default context object
	 * @param {String} username Username taken from the URL param
	 * @returns the response so we can use 'await' on this action call elsewhere
	 */
	async fetchUserData(context, username) {
		return await axios
			.get(`/api/user/findUser/${username}`)
			.then(async (response) => {
				// Commits the user's data to the store
				context.commit("setUserData", response.data)
			})
			.catch((err) => console.log(err.response))
	},

	clearUserData(context) {
		context.commit("setUserData", {})
	},

	async getAllUsers() {
		const response = await axios
			.get("/api/user/getAllUsers")
			.then((response) => {
				return response.data
			})
			.catch((err) => {
				console.log("FAILURE!!", err.response.data.message)
				this.errorMessage = err.response.data.message
			})

		return response
	},

	async createUser(context, image) {
		let userData = JSON.stringify(context.getters.getUserData)

		//console.log("userData", userData)

		const formData = new FormData()
		formData.append("userData", userData)
		if (image) {
			console.log("appending image")
			formData.append("avatar", image)
		}
		return axios
			.post("/api/user/createUser", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				context.dispatch("clearUserData")
				let username = response.data.username
				return `User ${username} has been added`
			})
			.catch((err) => {
				return err.response.data.message
			})
	},
	async updateUser(context, image) {
		let userData = JSON.stringify(context.getters.getUserData)

		const formData = new FormData()
		formData.append("userData", userData)
		if (image) {
			console.log("appending image")
			formData.append("avatar", image)
		}
		return axios
			.post(`/api/user/updateUser/${context.getters.getUserUsername}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				// Need to set the new user data here
				context.commit("setUserData", response.data.userData)
				return response.data
			})
			.catch(function (err) {
				//console.log('FAILURE!!', err.response);
				return err.response.data
			})
	},

	async deactivateUser(context, userId) {
		return axios
			.delete(`/api/user/deleteUser/${userId}`)
			.then(() => {
				return router.replace("/admin/user-management")
			})
			.catch(function (err) {
				console.log("FAILURE!!", err.response)
			})
	},

	async logUserOut(context, userId) {
		return axios
			.get(`/api/user/logUserOut/${userId}`)
			.then((response) => {
				return response
			})
			.catch(function (err) {
				console.log("FAILURE!!", err.response)
				return err.response
			})
	},
	async deleteAccountData(context, accountType){
		console.log('deleteAccountData starting', [context.getters.getUserUsername, accountType]);
		try{
			const params = new URLSearchParams();    
			params.append('accountType', 'user');
			params.append('username', context.getters.getUserUsername);
			await axios.post('/api/clients/deleteAccountData', params, {
				headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				},
			});
			alert('Account has been removed');
			console.log('redirecting to: ', window.location.hostname);
			let port = window.location.port ? ':' + window.location.port : '';
			if (window.location.hostname === 'localhost') {
				port = ':8080';
			}
			window.location.href = window.location.protocol + "//" + window.location.hostname + port;

		}catch(error){
			console.log('deleteAccountData error', error);
			alert(`Sorry, we seem to be unable to delete Account Data. Please try submitting a bug report. The error: ${error}`);
		}
	}
}
