import axios from 'axios';

import {conversionRate} from '../../../../../mixins/conversionRateFunc';
import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {

    async headerGraphBlockInit(context){

        try{
            // API urls
            
            
            const metricsURL = `/api/google/metric?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&analytics=v4&metrics=`;
            const metricClean = 'sessionsByDay';
            const metricChannel = `sessionSource`;
            const metricMom = 'sessionsMom';
            const momDays = 56;
            let updatedGoogleData;

            const response = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`)
            
            updatedGoogleData = response.data;

            let timeNow = Date.now();
            // If this is the first time it's been loaded there's nothing in the database so:
            // 1. set the time higher to ensure the API data is collected
            let timeUpdated = dayMilliseconds + 1;
            
            if(response.data.metrics){
                if (response.data.metrics[metricMom]) {
                    // 2. If the data does exist replace the time updated
                    timeUpdated = response.data.metrics[metricMom].updated;
                } else {
                    // And if it doesn't add a placeholder for the new metric
                    updatedGoogleData.metrics[metricMom] = {}
                }
            }else{
                // And if it doesn't add a placeholder for the new metric
                updatedGoogleData.metrics[metricMom] = {}
            }
    
            let timeSince = timeNow - timeUpdated;

            if (timeSince > dayMilliseconds) {

                if(updatedGoogleData.metrics){
                    updatedGoogleData.metrics[metricMom].currentPaid = 0
                    updatedGoogleData.metrics[metricMom].currentOrganic = 0
                    updatedGoogleData.metrics[metricMom].previousPaid = 0
                    updatedGoogleData.metrics[metricMom].previousOrganic = 0;
                }
                //This month
                const thisMonthQS = `${metricChannel}&startDate=28daysAgo&endDate=1daysAgo`;
                const response = await axios.get(metricsURL + thisMonthQS)
                const responseData = response.data.data.rows;
                const PaidSocialObj = responseData.find(item => item.dimensionValues[2].value === 'Paid Social' && item.dimensionValues[1].value === 'facebook.com')
                const OrganicSocialObj = responseData.find(item => item.dimensionValues[2].value === 'Organic Social' && item.dimensionValues[1].value === 'facebook.com')
                if(PaidSocialObj){
                    updatedGoogleData.metrics[metricMom].currentPaid = Number(PaidSocialObj.metricValues[1].value);
                }
                if(OrganicSocialObj){
                    updatedGoogleData.metrics[metricMom].currentOrganic = Number(OrganicSocialObj.metricValues[1].value);
                }
                if(updatedGoogleData.metrics){
                    context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/paidSessionsVal', val: updatedGoogleData.metrics[metricMom].currentPaid});
                    context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/organicSessionsVal', val: updatedGoogleData.metrics[metricMom].currentOrganic});
                }
                // Last month
                let lastMonthQS = `${metricChannel}&startDate=56daysAgo&endDate=28daysAgo`;
                const response1 = await axios.get(metricsURL + lastMonthQS);
                const response1Data = response1.data.data.rows;
                const PaidSocialObj1 = response1Data.find(item => item.dimensionValues[2].value === 'Paid Social' && item.dimensionValues[1].value === 'facebook.com')
                const OrganicSocialObj1 = response1Data.find(item => item.dimensionValues[2].value === 'Organic Social' && item.dimensionValues[1].value === 'facebook.com')
                if(PaidSocialObj){
                    updatedGoogleData.metrics[metricMom].previousPaid = Number(PaidSocialObj1.metricValues[1].value);
                }
                if(OrganicSocialObj){
                    updatedGoogleData.metrics[metricMom].previousOrganic = Number(OrganicSocialObj1.metricValues[1].value);
                }
                context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/paidConversionAmount', val: (context.getters.getComponents.paidSocial.HeaderGraphBlock.paidSessionsVal - updatedGoogleData.metrics[metricMom].previousPaid)});
                context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/organicConversionAmount', val: (context.getters.getComponents.paidSocial.HeaderGraphBlock.organicSessionsVal - updatedGoogleData.metrics[metricMom].previousOrganic)});
                updatedGoogleData.metrics[metricMom].updated = Date.now()
                await setGoogleData(updatedGoogleData.metrics[metricMom], metricMom, context.getters.getClientSlug);
                // Set the classes based on the rate
                context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/paidConversionClass', val: conversionRate(context.getters.getComponents.paidSocial.HeaderGraphBlock.paidConversionAmount)});
                context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/organicConversionClass', val: conversionRate(context.getters.getComponents.paidSocial.HeaderGraphBlock.organicConversionAmount)});

            } else {
                /*
                * The data is less than a day old, so grab the data from the database.
                */
                if(response.data.metrics){
                    context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/paidSessionsVal', val: response.data.metrics[metricMom].currentPaid});
                    context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/organicSessionsVal', val: response.data.metrics[metricMom].currentOrganic});
                    context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/paidConversionAmount', val: (context.getters.getComponents.paidSocial.HeaderGraphBlock.paidSessionsVal - response.data.metrics[metricMom].previousPaid)});
                    context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/organicConversionAmount', val: (context.getters.getComponents.paidSocial.HeaderGraphBlock.organicSessionsVal - response.data.metrics[metricMom].previousOrganic)});
                    // Set the classes based on the rate
                    context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/paidConversionClass', val: conversionRate(context.getters.getComponents.paidSocial.HeaderGraphBlock.paidConversionAmount)});
                    context.commit('setComponents', {prop: 'paidSocial/HeaderGraphBlock/organicConversionClass', val: conversionRate(context.getters.getComponents.paidSocial.HeaderGraphBlock.organicConversionAmount)});

                }
            }

            //updatedGoogleData.metrics[metricMom]

            /*
            * connect and grab the daily visitors for the last month, to create the graph.
            */
            const response3 = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`)
            
            updatedGoogleData = response3.data;

            if(response.data.metrics){

                if (response.data.metrics[metricClean]) {
                    // 2. If the data does exist replace the time updated
                    timeUpdated = response.data.metrics[metricClean].updated;
                } else {
                    // And if it doesn't add a placeholder for the new metric
                    updatedGoogleData.metrics[metricClean] = {}
                    updatedGoogleData.metrics[metricClean].paid = {}
                    updatedGoogleData.metrics[metricClean].organic = {}
                }

            }else{
                // And if it doesn't add a placeholder for the new metric
                updatedGoogleData.metrics[metricClean] = {}
                updatedGoogleData.metrics[metricClean].paid = {}
                updatedGoogleData.metrics[metricClean].organic = {}
            }

            if (timeSince > dayMilliseconds) {
                /*
                * The data is over a day old, so update from the API and save the updated information to the database.
                */
                console.log('updated 56 day organic sessions over a day ago');

                let promises = []; // Create an array to store promises

                for (let i = momDays; i > 0; i--) {
                    const date = new Date();
                    date.setDate(date.getDate() - i);
                    const formattedDate = date.toISOString().slice(0, 10);
                    const currentRevenueQS = `${metricChannel}&startDate=${formattedDate}&endDate=${formattedDate}`;
                    // Create a promise for the axios request and push it to the array

                    promises.push((async () => {
                        const response = await axios.get(metricsURL + currentRevenueQS);
                        const rows = response.data.data.rows;

                        updatedGoogleData.metrics[metricClean].paid[formattedDate] = 0
                        updatedGoogleData.metrics[metricClean].organic[formattedDate] = 0

                        const paidSocialData = rows.find(item => item.dimensionValues[1] === 'facebook.com' && item.dimensionValues[1] === 'Paid Social');
                        const organicSocialData = rows.find(item => item.dimensionValues[1] === 'facebook.com' && item.dimensionValues[1] === 'Organic Social');

                        if(paidSocialData){
                            updatedGoogleData.metrics[metricClean].paid[formattedDate] = paidSocialData.metricValues[0].value;
                        }
                        if(organicSocialData){
                            updatedGoogleData.metrics[metricClean].organic[formattedDate] = organicSocialData.metricValues[0].value;
                        }

                        updatedGoogleData.metrics[metricClean].updated = Date.now();

                    })());
                }

                try {
                    await Promise.all(promises);
                } catch (error) {
                    console.error(error);
                }

                await setGoogleData(updatedGoogleData.metrics[metricClean], metricClean, context.getters.getClientSlug);

            }

            return updatedGoogleData;

        }catch(error){
            console.log('headerGraphBlockInit catch error', error);
        }

    },

}