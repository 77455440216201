import axios from 'axios';

import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {

    async SEOgoalCompletionsBlockInit(context){

        try{

            // API urls
            
            
            const metricsURL = `/api/google/metric?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&analytics=v4&metrics=`;
            const metricClean = 'firstUserMedium';
            const metricChannel = `${metricClean}`;
            const metricJSON = 'acquisitionMediumGoals';
            let createNewMetric = false;
            let updatedGoogleData;
            
            // Work out the time since the last update
            let timeNow = Date.now();

            // If this is the first time it's been loaded there's nothing in the database so:
            // 1. set the time higher to ensure the API data is collected
            let timeUpdated = dayMilliseconds + 1;

            if (context.getters.getClientGoogleData.metrics[metricJSON]) {
                // 2. If the data does exist replace the time updated & set the initial variables from the DB
                timeUpdated = context.getters.getClientGoogleData.metrics[metricJSON].updated;
                context.commit('setComponents', {prop: 'seo/GoalCompletionsBlock/goalsOrganic', val: Math.floor(context.getters.getClientGoogleData.metrics[metricJSON].organic)});
            } else {
                // And if it doesn't add a placeholder for the new metric
                createNewMetric = true
            }

            let timeSince = timeNow - timeUpdated;
            // If the data hasn't been updated for over a day update from the API
            if (timeSince > dayMilliseconds) {

                const response = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`);
                /*
                * The data is over a day old - or not set - so update from the API and save the updated information to the database.
                */
                console.log('updated Goal completions over a day ago');
                updatedGoogleData = response.data;

                if (createNewMetric) {
                    updatedGoogleData.metrics[metricJSON] = {}
                }
                /*
                * connect to the API and get the data for the Average session duration this month, then compare with last month.
                */
                let currentRevenueQS = `${metricChannel}&startDate=28daysAgo&endDate=1daysAgo`;
                const res = await axios.get(metricsURL + currentRevenueQS);
                const data = res.data.data.rows;
                const organicData = data.find(item => item.dimensionValues[1].value === 'organic');

                if(organicData){
                    let totalUsers = Number(organicData.metricValues[0].value);
                    let goalsPerUser = Number(organicData.metricValues[2].value) / Number(organicData.metricValues[1].value); //ga:cohortGoalCompletionsPerUser  = eventCount / cohortActiveUsers
                    let goalCompletionsTotal = (goalsPerUser * totalUsers)
                    updatedGoogleData.metrics[metricJSON].organic = goalCompletionsTotal
                }else{
                    updatedGoogleData.metrics[metricJSON].organic = 0
                }

                updatedGoogleData.metrics[metricJSON].updated = Date.now();

                await setGoogleData(updatedGoogleData.metrics[metricJSON], metricJSON, context.getters.getClientSlug);

               context.commit('setComponents', {prop: 'seo/GoalCompletionsBlock/goalsOrganic', val: Math.floor(updatedGoogleData.metrics[metricJSON].organic)});

            }

        }catch(error){

            console.log('analytics error init', error);

        }
        
    },
}