import headerGraphBlock from './headerGraphBlock';
import analyticsAverageSessionDurationBlock from './analyticsAverageSessionDurationBlock';
import analyticsBounceRate from './analyticsBounceRate';
import analyticsConversionsBlock from './analyticsConversionsBlock';
import analyticsPageSessionBlock from './analyticsPageSessionBlock';
import analyticsRevenueBlock from './analyticsRevenueBlock';
import analyticsSessionByChannelBlock from './analyticsSessionByChannelBlock';
export default {

    ...headerGraphBlock,
    ...analyticsAverageSessionDurationBlock,
    ...analyticsBounceRate,
    ...analyticsConversionsBlock,
    ...analyticsPageSessionBlock,
    ...analyticsRevenueBlock,
    ...analyticsSessionByChannelBlock

}