import clientGetters from './getters.client';
import clientMutations from './mutations.client';
import clientActions from './actions.client';
import { Node, NodeBlogContent, Comment, Social, ScheduledBlogPosts, Components, AIPopUpFields, reportProblem } from '../../../services/contentNodes';

export default {
	namespaced: true,
	state() {
		return {
			clientData: {},
			authUsername: '',
			clientContentTree: {},
			reloadContentTree: false,
			importedContentTree: {links: [], nodes: []},
			rootNodeReach: 0,
			// Initially, we set the current Node to a blank class instance to prevent rendering
			// issues if the api call to fetch client data hasn't resolved yet.
			currentNode: new Node(),
			oldNodeId: '',
			zoomingAnimationOnTreeActive: false,
			currentNodeBlogContent: new NodeBlogContent(),
			scheduledBlogPosts: new ScheduledBlogPosts(),
			components: new Components(),
			AIPopUpFields: new AIPopUpFields(),
			blogModalOpen: false,
			importModalOpen: false,
			waitingForPostToPublish: false,
			loopThatChecksIfPostPublishedRunning: false,
			clientNodeMediaTempStorage: [],
			clientNodeComments: [],
			isCreatingNewNode: false,
			hasCurrentNodeBlogContentBeenEdited: false,
			listView: false,
			lockContentTree: true,
			hasCommentsBeenUpdated: false,
			createComment: false,
			openKeywordPlanner: false,
			openBlogEditor: false,
			openBlogEditorAddNodeForm: false,
			blogContentIsInDB: false,
			cancelComment: false,
			mentions: [],
			comment: new Comment(),
			activeComment: '',
			highlightedNode: new Node(),
			mediaList: [],
			contentTreeFilters: {},
			filteredNodes: [],
			addNodeFormOpen: false,
			openCommentPanel: true,
			hasCurrentNodeBeenEdited: false,
			editComment: '',
			replyComment: '',
			lockListCategories: false,
			googleAccessToken: '',
			userIsRequiredToReloadPage: false,
			taggedUser: {},
			userOpenedBlogOrSocialEditor: false,
			tokens : {},
			zoomToFitContentTree: false,
			keywordPlanner : {
				tableData : []
			},
			social : new Social(),
			activeNode: '',
			changedNode: '',
			nodeWasOrIsPosting: {status: false, error: ''},
			contentTreeSiblingIds: [],
			saveContentTree: false,
			isCreatingNewNodeToAddToDB: false,
			closeNodeOverview: false,
			acfPickerData: [],
			contentTreeWasResized: false,
			reportProblem: new reportProblem(),
			showEditor: false,
			openaddNodeClickTitle: false,
			taggedUsers: {
				isEditView: false,
				modalIsOpen: false,
				lastComponentClicked: false,
				activeComponent: '',
				allUsers: [],
				selected: [],
				selectedString: ''
			},
			mutliSelectNodeCategories: {
				isEditView: false,
				modalIsOpen: false,
				lastComponentClicked: false,
				activeComponent: '',
				allUsers: [],
				selected: [],
				filteredCategoryNodes: ''
			},
			loaders: {
				removeRowLoader: false,
				hidePageLoader: false,
				hideContentTreeLoader: false
			},
			clientPagesLoaded : {
				analyticsComponents : {
					HeaderGraphBlock: false,
					PagesSessionBlock: false,
					AverageSessionDurationBlock : false,
					BounceRateBlock : false,
					ConversionsBlock : false,
					RevenueBlock : false,
					SessionByChannelBlock : false
				},
				paidSocialComponents : {
					HeaderGraphBlock: false,
					PaidSocialClicksBlock: false,
					FacebookImpressionsBlock : false,
					FacebookConversionsBlock : false,
					ConversionsBlock : false,
				},
				ppcComponents : {
					CostPerClickBlock: false,
					ConversionBlock: false,
					ConversionRateBlock : false,
					CostConversionBlock : false,
					CTRBlock : false,
					TotalCostBlock : false,
					AllDataBlock : false,
				},
				seoComponents : {
					HeaderGraphBlock : false,
					GoalCompletionsBlock : false,
					LatestRankingBlock : false,
					OrganicSessionsBlock : false,
					ClicksImpressionsBlock : false,
				},
				organicSocialComponents : {

					HeaderGraphBlock : false,
					FacebookEngagementsBlock : false,
					FacebookLikesBlock : false,
					FacebookConversionsBlock : false,
					ConversionsBlock : false,

				}
			},
		};
	},
	mutations: clientMutations,
	actions: clientActions,
	getters: clientGetters,
};
