
import axios from 'axios';

export default {
    async notifyMainContactForApproval(context){
		try{
            //this function also updated the status to 'Pending Review' in the database.
			const params = new URLSearchParams();    
			params.append('slug', context.getters.getClientSlug);
            params.append('nodeId', context.getters.getCurrentNode.id);
			const response = await axios.post('/api/clients/notifyMainContactForApproval', params, {
				headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				},
			});
            if(response.data === true){
                alert(`${context.getters.getClientData.mainContact.username} has been notified for approval`);
            }else{
                alert(`${context.getters.getClientData.mainContact.username} could not be notified for approval`);
            }
            console.log('notifyMainContactForApproval action response', response);

			// //change status of node to awaiting approval and save it. 
			context.commit("setNodeField", {field : "status", fieldGroup : "status", value : "Pending Review"})

		}catch(error){
			console.log('notifyMainContactForApproval action catch error', error);
		}
	},
}