const conversionRate = {

    methods: {
        /**
         * Updates the googleData col in the database.
         * @param {data} str The stingified json
         */
        conversionRate(before) {
            if ( before === 0 || before === 'NaN' ) {
              return 'level';
            } else if ( before < 0 ) {
              return 'down';
            } else {
              return 'up';
            }
        },
    },
};

export default conversionRate;
