import axios from 'axios';

import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {

    async analyticsConversionsBlockInit(context){
        try{

            // API urls
            const metricClean = 'conversions';
            
            
            const propertyID = context.getters.getSocial.tokens.google.accountID;
            const goalsURL = `/api/google/goals?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&propertyid=${propertyID}`;
            // let goalsTotal = 0;
            let createNewMetric = false;
            let updatedGoogleData;
            let goalNames;
            let goalValues;
            let goalGraphNames;
            let goalGraphValues;
            let total;

            // Work out the time since the last update
            let timeNow = Date.now();
            // If this is the first time it's been loaded there's nothing in the database so:
            // 1. set the time higher to ensure the API data is collected
            let timeUpdated = dayMilliseconds + 1;

            if (context.getters.getClientGoogleData.metrics[metricClean]) {
                // 2. If the data does exist replace the time updated & set the initial variables from the DB
                timeUpdated = context.getters.getClientGoogleData.metrics[metricClean].updated;
                goalNames = JSON.parse(context.getters.getClientGoogleData.metrics[metricClean].goalNames);
                goalValues = JSON.parse(context.getters.getClientGoogleData.metrics[metricClean].goalValues);
                total = context.getters.getClientGoogleData.metrics[metricClean].conversionsTotal;
                goalGraphNames = [];
                goalGraphValues = [];
                for (var i = 0; i < goalNames.length; i++) {
                    if ( goalValues[i] > 0 ) {
                    goalGraphNames.push(goalNames[i]);
                    goalGraphValues.push(goalValues[i]);
                    }
                }

                context.commit('setComponents', {prop: 'analytics/ConversionsBlock/conversionsTotal', val: total});
                context.commit('setComponents', {prop: 'analytics/ConversionsBlock/conversionsSeries', val: goalGraphValues});
                context.commit('setComponents', {prop: 'analytics/ConversionsBlock/legendLabels', val: goalNames});
                context.commit('setComponents', {prop: 'analytics/ConversionsBlock/numberOfLegendItems', val: Math.ceil(goalNames.length / 2)});
                // Note: on load the labels don't load. For some reason the apex chart just won't work correctly with them before it's been rendered.
                // so they always load in the update code, which makes no sense, but does work.
            } else {
            // And if it doesn't add a placeholder for the new metric
                createNewMetric = true
            }

            let timeSince = timeNow - timeUpdated;

            const response = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`);

            updatedGoogleData = response.data;

            // If the data hasn't been updated for over a day update from the API
            if (timeSince > dayMilliseconds) {
                /*
                * The data is over a day old, so update from the API and save the updated information to the database.
                */
                console.log('updated Conversions over a day ago');

                if (createNewMetric) {

                    updatedGoogleData.metrics[metricClean] = {}

                }
                /*
                * Get the names and ids of the goals
                * The names/ids come from the management API rather than the analytics
                * So we have to fetch them, then loop through the URLs for the specific goals to get the number of completions
                * Which means a bunch of requests rather than updating in one go.
                */

                //GET THE LABELS FOR THE GRAPH
                const res = await axios.get(goalsURL);
                console.log('step11111', [res.data.data, goalsURL]);
                const data = res.data.data.rows ? res.data.data.rows : res.data.data;

                let conversions = [];
                let conversionLabels = [];
                let conversionValues = [];
                let conversionTotal = 0;

                for (let i = 0; i < data.length; i++) {

                    const id = i;
                    const label = data[i].dimensionValues[0].value;
                    const value = data[i].metricValues[0].value;

                    conversionTotal += Number(value);

                    conversions.push({'id': id, 'name': label, 'value': value})
                    conversionLabels.push('<span>' + label + '</span><span>' + value + '</span>')
                    conversionValues.push(value)

                }

                context.commit('setComponents', {prop: 'analytics/ConversionsBlock/conversionsTotal', val: conversionTotal});
                context.commit('setComponents', {prop: 'analytics/ConversionsBlock/legendLabels', val: conversionLabels});
                context.commit('setComponents', {prop: 'analytics/ConversionsBlock/numberOfLegendItems', val: Math.ceil(conversionLabels.length / 2)});
                // Set the data for storing
                updatedGoogleData.metrics[metricClean].updated = Date.now()
                updatedGoogleData.metrics[metricClean].conversionsTotal = conversionTotal
                updatedGoogleData.metrics[metricClean].goalNames = JSON.stringify(conversionLabels)
                updatedGoogleData.metrics[metricClean].goalValues = JSON.stringify(conversionValues)
                await setGoogleData(updatedGoogleData.metrics[metricClean], metricClean, context.getters.getClientSlug)

            }

            return updatedGoogleData;

        }catch(error){
            console.log('init error', error);
        }
    }
}