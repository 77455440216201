import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import VueNextSelect from 'vue-next-select';
import 'vue-next-select/dist/index.css';
import authorizationMixins from './mixins/authorizationMixins';
import slugify from './mixins/slugify';
import formatPounds from "./mixins/formatPounds";
import conversionRate from "./mixins/conversionRate";
import numberWithCommas from "./mixins/numberWithCommas";
import abbreviateNumber from "./mixins/abbreviateNumber";
import formatPercentage from "./mixins/formatPercentage";
import setGoogleData from "./mixins/setGoogleData";
import setFacebookData from "./mixins/setFacebookData";
import useValidate from '@vuelidate/core';
// import ColorTracker from "canvas-color-tracker"
import VueApexCharts from "vue3-apexcharts";


// VUE 3 NOT YET SUPPORTED WITH FONT AWESOME VUE COMPONENT
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core"

// /* import specific icons */
import { faPenToSquare } from "@fortawesome/pro-duotone-svg-icons"

// /* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

/* add icons to the library */
library.add(faPenToSquare);
const app = createApp(App);
// // Uncomment this to disable vue warnings
// app.config.errorHandler = () => null;
// app.config.warnHandler = () => null;
// Global vars
app.config.globalProperties.dayMilliseconds = 86400000;
app.use(store);
// Mixins
app.mixin(authorizationMixins);
app.mixin(slugify);
app.mixin(formatPounds);
app.mixin(conversionRate);
app.mixin(numberWithCommas);
app.mixin(abbreviateNumber);
app.mixin(formatPercentage);
app.mixin(setGoogleData);
app.mixin(setFacebookData);
app.use(router, axios);
app.use(useValidate());
app.use(VueApexCharts);
// app.use(ColorTracker())
/* add font awesome icon component */
app.component("FontAwesomeIcon", FontAwesomeIcon)
app.component('Datepicker', Datepicker);
app.component('VueNextSelect', VueNextSelect);
app.mount('#app');
