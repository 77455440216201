function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))
}

class Field {
	constructor(defaultValue, label) {
		this.id = `field_${uuidv4()}`
		this.label = label
		this.value = defaultValue
	}
}

const defaultFields = {
	hideFromUser: new Field(false, "Hide from user"),
}

class FieldGroup {
	constructor(title, fields) {
		this.id = `field_group-${uuidv4()}`
		this.title = title
		this.fields = {...defaultFields, ...fields}
	}
}

class Node {
	constructor(parent, author) {
		//console.log(author, "test in node class")
		this.id = ``
		this.parent = parent ? parent.id : "root"
		this.category = parent ? this.categoryID(parent) : 'root'
		this.childIds = []
		this.childCount = 0
		this.hasChildren = false
		this.name = this.computeName(parent)
		this.level = parent ? parent.level + 1 : 0
		
		this.accumulativeReach = 0
		this.historicalAccumulativeReach = []

		this.fieldGroups = {
			title: new FieldGroup("Title", {title: new Field("", "Title")}),
			details: new FieldGroup("Details", {details: new Field("", "Details")}),
			assign: new FieldGroup("Assign", {assign: new Field("", "Assign User")}),
			deadline: new FieldGroup("Deadline", {deadline: new Field(new Date(), "Deadline")}),
			keyword: new FieldGroup("Key Word", {keyword: new Field("", "Keyword"), reach: new Field(0, "Reach")}),
			format: new FieldGroup("Format", {format: new Field("", "Format"), focus: new Field("", "Focus")}),
			intent: new FieldGroup("Intent", {intent: new Field("", "Intent")}),
			research: new FieldGroup("Research", {research: new Field("", "Research")}),
			status: new FieldGroup("Status", {status: new Field("Title created", "Status"), notes: new Field("", "Notes")}),
			author: new FieldGroup("Author", {author: new Field(author, "Author")}),
			created: new FieldGroup("Created", {created: new Field(new Date(), "Created")}),
		}
		this.siblings = [],
		this.preConnectedSocialPostID = ''
	}
	computeName(parent) {
		if (parent) {
			return `${parent.name}.${Number(parent.childCount) + 1}`
		} else {
			return "1"
		}
	}
	categoryID(parent){
		if(parent.level === 0){
			return 'self';
		}
		if(parent.level === 1){
			return parent.id;
		}
		if(parent.level >= 2){
			return parent.category;
		}
	}
}
class NodeBlogContent {
	constructor() {
		//console.log(author, "test in node class")
		this.id = `blog_${uuidv4()}`,
		this.nodeID = '',
		this.featuredImageURL = '',
		this.blogHTML = ''
		this.reviewed = false,
		this.title = '',
		this.hasbeenPublished = false
		this.date = new Date()
		//this.revision = 1
	}
}
class Comment {
	constructor() {
		this.id = `${uuidv4()}`,
		this.nodeID = '',
		this.comment = '',
		this.user = '',
		this.parentCommentID = '',
		this.resolved = false
	}
}
class Mention {
	constructor() {
		this.id = `${uuidv4()}`,
		this.commentID = '',
		this.comment = '',
		this.commentCreator = '',
		this.username = '',
		this.email = ''
	}
}

// class NodeLink {
// 	constructor(sourceId, targetId) {
// 		this.source = sourceId
// 		this.target = targetId
// 	}
// }

class ContentTree {
	constructor() {
		this.links = []
		this.nodes = []
		this.nodeIds = []
		this.rootNodeId = ""
		this.addRootNode()
	}

	// async beforeSaveTree(currentNode) {
	// 	await this.accumulateReach(currentNode)
	// 	return
	// }

	cleanTree() {
		this.links = []
		this.nodes = {}
	}

	addRootNode() {
		const newRootNode = new Node()
		this.nodes.push(newRootNode)
		this.rootNodeId = newRootNode.id
		this.nodeIds.push(newRootNode.id)
	}

	addChildNode(parentID, author) {
		let parentNodeArray = this.nodes.filter((node) => node.id === parentID);
		let parentNode = parentNodeArray[0];
		const newNode = new Node(parentNode, author);
		console.log('THE NEW NODE:', newNode);
		return newNode
	}

	/**
	 * This function removes a node from the node tree, will only work if the node has no children
	 * Updates the nodeLink list and the parent node
	 * @param {String} nodeID the ID of the node to be removed
	 */
	removeNode(nodeID) {
		let currentNodeArray = this.nodes.filter((node) => node.id === nodeID),
			currentNode = currentNodeArray[0],
			parentNode = this.nodes.find((node) => node.id === currentNode.parent)

		if (currentNode.hasChildren === false) {
			// Find the index of the node to remove & see if it exists
			const findIndex = this.nodes.findIndex((currentNode) => currentNode.id === nodeID)

			// If the node exists
			if (findIndex > -1) {
				// Remove the node
				this.nodes.splice(findIndex, 1)

				// Update the link list
				this.links = this.links.filter((pair) => pair.target !== nodeID)

				// Update the parent node
				parentNode.childIds = parentNode.childIds.filter((id) => id !== nodeID)
				parentNode.childCount = parentNode.childCount - 1
				if (parentNode.childCount <= 0) {
					parentNode.hasChildren = false
				}
				this.nodeIds = this.nodeIds.filter((id) => id !== nodeID)

				// console.log("parentNode", parentNode)
				// console.log(this.nodes)
				return
			}
		}
	}
}

class SocialTokens {
	constructor() {
		this.username = '',
		this.meta =  {
			longliveAccessToken: '',
			pageAccessToken: '',
			instaPageID: '',
			facebookPageID: '',
			expire: '',
			allFacebookPages: [],
			facebookSlug: ''
		},
		this.twitter =  {
			accessToken: '',
			accessTokenSecret: '',
			expire: '',
			username: '',
			pageName: ''
		},
		this.linkedin =  {
			accessToken: '',
			companyID: '',
			expire: '',
			userLinkedCompanyData: []
		},
		this.google = {
			accessToken : '',
			refreshToken: '',
			userLinkedCompanyAccounts: [],
			userLinkedViews: [],
			accountID: '', //propertyID
			viewID: '',
			uaID: '', //webPropertyId //gauID
			adsAccountID: '',
			GA4PropertyID: '',
			searchConsoleURL: '',
			agencyCampaign: '',
			agencyAPIkey: '',
			expire: '',
			userLinkedProperties: '',
			profileId: ''
		}
		this.wordpress = {
			url: '',
			username: '',
			password: ''
		}
	}
	calculateMetaExpireDate(date) {
		return new Date(date.getTime() + 60 * 24 * 60 * 60 * 1000);
	}
}
function roundNewDateToNearest5(){
	var coeff = 1000 * 60 * 5;
	var date = new Date();
	var rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff);
	return rounded;
}
class livePostLinks{
	constructor() {
		this.facebook =  {
			url: '',
			id: '',
		},
		this.instagram =  {
			url: '',
			id: '',
		},
		this.twitter =  {
			url: '',
			id: '',
		},
		this.linkedin =  {
			url: '',
			id: '',
		}
	}
}
class SocialPost {
	constructor() {
		this.id = `${uuidv4()}`,
		this.nodeID = ''
		this.publishDate = roundNewDateToNearest5(),
		this.clientSlug = '',
		this.caption = '',
		this.media = [],
		this.scheduleTo = {
			facebook: false,
			instagram: false,
			twitter: false,
			linkedin: false
		},
		this.approvedForPublishing = false,
		this.hasbeenPublished = false,
		this.createdByUsername = '',
		this.livePostLinks = new livePostLinks(),
		this.siblingNodesCreated = false,
		this.error = ''
	}
}

class SocialAccountInformation {
	constructor(){
		this.meta = {
			userID: '',
			username: '',
			fbPageName: '',
			fbPageLink: '',
			instaPageName: '',
			instaPageLink: '',
			fbPageTokenStatus: {
				is_valid: '',
				data_access_expires_at: ''
			},
			fbUserTokenStatus: {
				is_valid: '',
				data_access_expires_at: ''
			}
		},
		this.twitter = false,
		this.linkedin = false,
		this.google = false,
		this.wordpress = false
	}
}

class Social {
	constructor() {
		this.tokens = new SocialTokens();
		this.post = new SocialPost();
		this.calendar = [],
		this.currentPageSectionThatIsOpen  = '',
		this.calendarIsOpen = '',
		this.modalIsOpen = '',
		this.editModalIsOpen = ''
		this.accountSelectIsOpen = false,
		this.googleModalIsOpen = false,
		this.ACFModalIsOpen = false,
		this.openSocial = false,
		this.singleAccountAuthInfo = false,
		this.socialAccountInformation = new SocialAccountInformation()
	}
}

class ScheduledBlogPosts{
	constructor() {
		this.blogID = '';
		this.date = new Date();
		this.hasbeenPublished = false;
	}
}

class reportProblem{
	constructor() {
		this.modalOpen = false;
		// this.id = false;
		// this.date = false;
		this.clientSystem = false;
		// this.type = false;
		this.screenshot = false
		this.clientSlug = false;
		this.currentNode = false;
		this.currentURL = false;
		// this.type = false,
		// this.action = false,
		this.moreInfo = false,
		this.user = false
		this.title = false
	}
}

class AIPopUpFields{
	constructor(){
		this.modals = {
			social: false,
			blog: false,
			titleField: false,
			detailsField: false,
			node: false
		}
		//social and blog
		this.keywords = ''
		this.voice = 'Expert'
		this.special = ''
		this.details = ''
		this.intent = ''
		this.format = ''
		this.response = ''
		//node generation and fields
		this.getParentData = true
		this.instructions = ''
		this.generatedContent = ''
	}
}


class componentData{
	constructor() {
		this.data = {};
	}
}
class Components{
	constructor(){
		this.analytics = {
			HeaderGraphBlock: {
				sessionsVal: '',
				conversionAmount: '',
				conversionClass: ''
			},
			PagesSessionBlock: {
				updatedGoogleData: '',
				pagesSessionVal: '--',
				pagesSessionComparison: '--',
				pagesSessionClass: 'up',
			},
			AverageSessionDurationBlock : {
				avgSessionVal: '--',
				avgSessionComparison: '--',
				avgSessionClass: 'up',
			},
			BounceRateBlock : {
				updatedGoogleData: '',
				bounceRateVal: '--',
				bounceRateComparison: '--',
				bounceRateClass: 'up',
			},
			ConversionsBlock : {
				conversionsSeries: '',
				legendLabels: '',
				numberOfLegendItems: '',
				conversionsTotal: ''
			},
			RevenueBlock : {
				revenue: '--'
			},
			SessionByChannelBlock : {
				channelsMax: 0,
				channelsOrganic: 0,
				channelsPaid: 0,
				channelsDirect: 0,
				channelsReferral: 0,
			}
		}
		this.paidSocial = {
			HeaderGraphBlock: new headerGraphComponent(),
			PaidSocialClicksBlock: {
				isFBReady: false,
				thisMonthsPaid: '--',
				lastMonthsPaid: '--',
				paidClass: '--',
			},
			FacebookImpressionsBlock : {
				updatedGoogleData: '',
				thisMonthsPaid: '--',
				lastMonthsPaid: '--',
				paidClass: '--',
			},
			FacebookConversionsBlock : {
				totalPaidConversions: '--',
				totalPaidConversionsPrevious: '--',
				paidConversionClass: 'up',
				runningTotal: 0,
				runningTotalPrevious: 0
			},
			ConversionsBlock : {
				conversionsSeries : '',
				legendLabels : '',
				numberOfLegendItems : '',
				conversionsTotal: '',
			}
		}
		this.ppc = {
			CostPerClickBlock: {
				updatedGoogleData: '',
				cpcVal: '--',
				cpcValClean: '--',
				cpcComparison: '--',
				cpcClass: 'up',
			},
			ConversionBlock: {
				updatedGoogleData: '',
				conversionVal: '--',
				conversionComparison: '--',
				conversionClass: 'up',
			},
			ConversionRateBlock : {
				updatedGoogleData: '',
				conversionRateVal: '--',
				conversionRateComparison: '--',
				conversionRateClass: 'up',
			},
			CostConversionBlock : {
				updatedGoogleData: '',
				costConversionVal: '--',
				costConversionComparison: '--',
				costConversionClass: 'up',
			},
			CTRBlock : {
				updatedGoogleData: '',
				ctrVal: '--',
				ctrComparison: '--',
				ctrClass: 'up',
			},
			TotalCostBlock : {
				updatedGoogleData: '',
				costVal: '--',
				costComparison: '--',
				costClass: 'up',
			},
			AllDataBlock : {
				updatedGoogleData: '',
            	tableRows: [],
			},
		}
		this.seo = {
			HeaderGraphBlock : {
				seoConversion: '',
				conversionClass: ''
			},
			GoalCompletionsBlock : {
				goalsOrganic: '--',
			},
			LatestRankingBlock : {
				ranking: '--',
			},
			OrganicSessionsBlock : {
				organicSessionsClass: '',
				totalOrganicSessionsPrevious: '',
				totalOrganicSessions: '',
				dailyHits: [],
				dailyLabels: []
			},
			ClicksImpressionsBlock : {
				dailyClicks: '',
				dailyImpressions: '',
				dailyLabels: ''
			},
		}
		this.organicSocial = {
			HeaderGraphBlock : new componentData(),
			FacebookEngagementsBlock : {
				isFBReady: false,
				thisMonthsOrganic: '--',
				lastMonthsOrganic: '--',
				organicClass: '--',
			},
			FacebookLikesBlock : new componentData(),
			FacebookConversionsBlock : new componentData(),
			ConversionsBlock : new componentData(),

		}
	}
}







class headerGraphComponent{
	constructor(){
		this.updatedGoogleData = '',
		this.paidSessionsVal = '--',
		this.paidConversionAmount = '--',
		this.paidConversionClass = 'up',
		this.organicSessionsVal = '--',
		this.organicConversionAmount = '--',
		this.organicConversionClass = 'up'
	}
}



function logTime(nodeData, step){
	const date = new Date();
	// Extract hours, minutes, and seconds using the local time
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const seconds = date.getSeconds().toString().padStart(2, '0');

	// Format the time in HH:mm:ss format
	const formattedTime = `${hours}:${minutes}:${seconds}`;

	console.log(`step-ac: ${step}: `, [nodeData, formattedTime]);
}




const exampleContentTree = new ContentTree()

const exampleNode = new Node()

export {exampleNode, exampleContentTree, reportProblem, ContentTree, Node, NodeBlogContent, Comment, Mention, Social, ScheduledBlogPosts, Components, SocialTokens, SocialAccountInformation, AIPopUpFields, logTime}

export default Node
