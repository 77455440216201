import axios from 'axios';
import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {

    async facebookConversionBlockInit(context){

         // API urls
         
         
         const metricsURL = `/api/google/metric?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&analytics=v4&metrics=`;
         const metricClean = 'socialSessionsByDay';
         const metricChannel = `ga:socialnetworkbits`; //this has been updated to GA4, this is just custom naming. check out the if statment in gAnalytics. 
         const momDays = 56;
         let updatedGoogleData;
         /*
          * connect and grab the daily visitors for the last month, to create the graph.
          */
         try{
 
            const response = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`);
 
             updatedGoogleData = response.data;

             let timeNow = Date.now();
             // If this is the first time it's been loaded there's nothing in the database so:
             // 1. set the time higher to ensure the API data is collected
             let timeUpdated = dayMilliseconds + 1;
             if (response.data.metrics[metricClean]) {
               // 2. If the data does exist replace the time updated
               timeUpdated = response.data.metrics[metricClean].updated;
             } else {
               // And if it doesn't add a placeholder for the new metric
               updatedGoogleData.metrics[metricClean] = {}
               updatedGoogleData.metrics[metricClean].facebook = {}
             }
             
             let timeSince = timeNow - timeUpdated;

             if (timeSince > dayMilliseconds) {

                 /*
                  * The data is over a day old, so update from the API and save the updated information to the database.
                  */
                
                let promises = []; // Create an array to store promises

                for (let i = momDays; i > 0; i--) {
                    let date = new Date();
                    date.setDate(date.getDate() - i);
                    date = date.toISOString().slice(0, 10);
                    const currentRevenueQS = `${metricChannel}&startDate=${date}&endDate=${date}`;

                    promises.push((async () => {
                        const response = await axios.get(metricsURL + currentRevenueQS);
                        const rows = response.data.data.rows;
                        const socialData = rows.find(item => item.dimensionValues[0] === 'facebook.com');
                        if(socialData){
                            const total_users = socialData.metricValues[0].value;
                            updatedGoogleData.metrics[metricClean].facebook[date] = socialData.metricValues[0].value;
                            if ( i > 28 ) {
                                context.commit('setComponents', {prop: 'paidSocial/FacebookConversionsBlock/runningTotalPrevious', val: context.getters.getComponents.paidSocial.FacebookConversionsBlock.runningTotalPrevious + parseInt(total_users)});
                            } else {
                                context.commit('setComponents', {prop: 'paidSocial/FacebookConversionsBlock/runningTotal', val: context.getters.getComponents.paidSocial.FacebookConversionsBlock.runningTotal + parseInt(total_users)});
                            }
                        }else{
                            updatedGoogleData.metrics[metricClean].facebook[date] = 0
                        }

                        updatedGoogleData.metrics[metricClean].updated = Date.now();

                    })());
                }

                try {
                    await Promise.all(promises);
                    await setGoogleData(updatedGoogleData.metrics[metricClean], metricClean, context.getters.getClientSlug);
                } catch (error) {
                    console.error(error);
                }

             }

             return updatedGoogleData;

         }catch(error){
             console.log('facebook conversion block catch error', error);
         }
    }

}