// import axios from 'axios';

// // import {conversionRate} from '../../../../mixins/conversionRateFunc';
// // import {setGoogleData} from "../../../../mixins/setGoogleDataFunc";
// // const dayMilliseconds = 86400000;

// const metric = 'facebookEngagement';
// let pageAccessToken;

// var dateFrom = new Date();
// var dateTo = new Date();
// dateFrom.setDate(dateFrom.getDate() - 57);
// dateTo.setDate(dateTo.getDate());
// dateFrom = Math.floor(dateFrom.getTime() / 1000);
// dateTo = Math.floor(dateTo.getTime() / 1000);


export default {

    // async FacebookEngagementsBlockInit(context){

    //     try{

    //         pageAccessToken = context.getters.tokens.meta.pageAccessToken;

    //         (function(d, s, id){
    //             var js, fjs = d.getElementsByTagName(s)[0]
    //             if (d.getElementById(id)) {return}
    //             js = d.createElement(s)
    //             js.id = id
    //             js.src = "//connect.facebook.net/en_US/sdk.js"
    //             fjs.parentNode.insertBefore(js, fjs)
    //             // console.log('setting fb sdk')
    //         }(document, 'script', 'facebook-jssdk'))
            
    //         window.fbAsyncInit = function onSDKInit() {
    //             window.FB.init({
    //               appId: "766915264635246",
    //               xfbml: true,
    //               version: "v14.0",
    //             });
    //             window.dispatchEvent(new Event('fb-sdk-ready'))
    //         }

    //         console.log('facebook engagement block init from JS file');
    
    //         this.isFBReady = window.FB != undefined
    
    //         window.addEventListener('fb-sdk-ready', this.onFBReady)
    
    //         if(this.facebookData.metrics){
    
    //             if (this.facebookData.metrics[metric]) {
    //                 // 2. If the data does exist replace the time updated
    //                 // this.thisMonthsPaid = this.facebookData.metrics[metric].paidCurrent
    //                 // this.lastMonthsPaid = (((parseFloat(this.facebookData.metrics[metric].paidCurrent) / parseFloat(this.facebookData.metrics[metric].paidPrevious)) * 100) - 100).toFixed(1)
    //                 this.thisMonthsOrganic = this.facebookData.metrics[metric].current
    //                 this.lastMonthsOrganic = (((parseFloat(this.facebookData.metrics[metric].current) / parseFloat(this.facebookData.metrics[metric].previous)) * 100) - 100).toFixed(1)
    //                 // Set the class based on the rate
    //                 // this.paidClass = this.conversionRate(this.lastMonthsPaid)
    //                 this.organicClass = this.conversionRate(this.lastMonthsOrganic);
    
    //                 this.componentHasAllData();
    
    //             }
    
    //         }else{
    
    //             console.log('error: this.facebookData.metrics underfined', this.facebookData);
    
    //         }

    //     }catch(error){
    //         console.log('error', error);
    //     }
    // },
    // // This function fires only when the Facebook SDK has loaded.
    // onFBReady: function () {
    //     this.isFBReady = true
    //     // console.log('fb ready')

    //     // Work out the updated date for the likes and either load from the DB or connect to the database
    //     let lastMonthEngagements = 0;
    //     let thisMonthEngagements = 0;
    //     let updatedFacebookData;
    //     let createNewMetric = false;
    //     let timeNow = Date.now();
    //     let timeUpdated = this.dayMilliseconds + 1;

    //   //  console.log('LUKE this.facebookData', this.facebookData);
        
    //     if(this.facebookData.metrics){

    //       if (this.facebookData.metrics[metric]) {

    //         timeUpdated = this.facebookData.metrics[metric].updated;

    //       } else {

    //         createNewMetric = true

    //       }

    //     }else{

    //       console.log('error onFBReady this.facebookData.metrics underfined');

    //       createNewMetric = true

    //     }

    //     let timeSince = timeNow - timeUpdated;

    //     if (timeSince > this.dayMilliseconds) {

    //       axios.get(`/api/clients/facebookData/${this.getClientSlug}`)
    //       .then((response) => {

    //       //  console.log('LUKE RESPONSE FROM DB:', response.data);

    //         let _this = this; // inside the loadjs/FB bits this refers to something else

    //         // console.log(response.data)
    //         /*
    //          * The data is over a day old, so update from the API and save the updated information to the database.
    //          */
    //         updatedFacebookData = response.data;

    //         if (createNewMetric) {

    //           updatedFacebookData.metrics[metric] = {}

    //         }

    //         console.log('this is the page access token:', pageAccessToken);

    //         window.FB.api('/' + updatedFacebookData.facebookSlug + '/insights?pretty=0&since=' + dateFrom + '&until=' + dateTo + '&metric=page_positive_feedback_by_type&access_token=' + pageAccessToken, function(engagementsResponse) {

    //           console.log('LUKE engagementsResponse', engagementsResponse.data[0].values)

    //           let engagementsArray = engagementsResponse.data[0].values;
    //           for (var oldEngagementsIndex = 0; oldEngagementsIndex < 28; oldEngagementsIndex++) {
    //               let thisDaysAnswers = engagementsArray[oldEngagementsIndex].value.answer
    //               let thisDaysClaims = engagementsArray[oldEngagementsIndex].value.claim
    //               let thisDaysComments = engagementsArray[oldEngagementsIndex].value.comment
    //               let thisDaysLikes = engagementsArray[oldEngagementsIndex].value.like
    //               let thisDaysLinks = engagementsArray[oldEngagementsIndex].value.link
    //               let thisDaysOther = engagementsArray[oldEngagementsIndex].value.other
    //               let thisDaysRSVPs = engagementsArray[oldEngagementsIndex].value.rsvp
    //               // Need to check these are numbers and not undefined
    //               if (Number.isInteger(thisDaysAnswers)) { thisMonthEngagements = thisMonthEngagements + thisDaysAnswers; }
    //               if (Number.isInteger(thisDaysClaims)) { thisMonthEngagements = thisMonthEngagements + thisDaysClaims; }
    //               if (Number.isInteger(thisDaysComments)) { thisMonthEngagements = thisMonthEngagements + thisDaysComments; }
    //               if (Number.isInteger(thisDaysLikes)) { thisMonthEngagements = thisMonthEngagements + thisDaysLikes; }
    //               if (Number.isInteger(thisDaysLinks)) { thisMonthEngagements = thisMonthEngagements + thisDaysLinks; }
    //               if (Number.isInteger(thisDaysOther)) { thisMonthEngagements = thisMonthEngagements + thisDaysOther; }
    //               if (Number.isInteger(thisDaysRSVPs)) { thisMonthEngagements = thisMonthEngagements + thisDaysRSVPs; }
    //           }
    //           for (var newEngagementsIndex = 28; newEngagementsIndex < 56; newEngagementsIndex++) {
    //               let thisDaysAnswers = engagementsArray[newEngagementsIndex].value.answer
    //               let thisDaysClaims = engagementsArray[newEngagementsIndex].value.claim
    //               let thisDaysComments = engagementsArray[newEngagementsIndex].value.comment
    //               let thisDaysLikes = engagementsArray[newEngagementsIndex].value.like
    //               let thisDaysLinks = engagementsArray[newEngagementsIndex].value.link
    //               let thisDaysOther = engagementsArray[newEngagementsIndex].value.other
    //               let thisDaysRSVPs = engagementsArray[newEngagementsIndex].value.rsvp
    //               // Need to check these are numbers and not undefined
    //               if (Number.isInteger(thisDaysAnswers)) { lastMonthEngagements = lastMonthEngagements + thisDaysAnswers; }
    //               if (Number.isInteger(thisDaysClaims)) { lastMonthEngagements = lastMonthEngagements + thisDaysClaims; }
    //               if (Number.isInteger(thisDaysComments)) { lastMonthEngagements = lastMonthEngagements + thisDaysComments; }
    //               if (Number.isInteger(thisDaysLikes)) { lastMonthEngagements = lastMonthEngagements + thisDaysLikes; }
    //               if (Number.isInteger(thisDaysLinks)) { lastMonthEngagements = lastMonthEngagements + thisDaysLinks; }
    //               if (Number.isInteger(thisDaysOther)) { lastMonthEngagements = lastMonthEngagements + thisDaysOther; }
    //               if (Number.isInteger(thisDaysRSVPs)) { lastMonthEngagements = lastMonthEngagements + thisDaysRSVPs; }
    //           }
    //           // Take off the paid likes as this metric doesn't differentiate
    //           // Possible flaw in this is there might not be a value if that data hasn't loaded, but that would only be for the first 24 hours of a client existing.
              
    //           if(Object.keys(updatedFacebookData.metrics).length > 1){

    //             lastMonthEngagements = lastMonthEngagements - updatedFacebookData.metrics.facebookLikes.paidPrevious
    //             thisMonthEngagements = thisMonthEngagements - updatedFacebookData.metrics.facebookLikes.paidCurrent

    //           }
  
    //           // console.log(engagementsArray);
    //           // console.log({lastMonthEngagements});
    //           // console.log({thisMonthEngagements});
    //           // console.log('pc:' + updatedFacebookData.metrics.facebookLikes.paidCurrent);
    //           // console.log('pp:' + updatedFacebookData.metrics.facebookLikes.paidPrevious);
    //           updatedFacebookData.metrics[metric].previous = lastMonthEngagements
    //           updatedFacebookData.metrics[metric].current = thisMonthEngagements
    //           updatedFacebookData.metrics[metric].updated = Date.now();
    //           // _this.thisMonthsPaid = thisMonthPaidEngagements
    //           // _this.lastMonthsPaid = (((parseFloat(thisMonthPaidEngagements) / parseFloat(lastMonthPaidEngagements)) * 100) - 100).toFixed(1)
    //           _this.thisMonthsOrganic = thisMonthEngagements
    //           _this.lastMonthsOrganic = (((parseFloat(thisMonthEngagements) / parseFloat(lastMonthEngagements)) * 100) - 100).toFixed(1)
    //           _this.setFacebookData(updatedFacebookData.metrics[metric], metric)
    //           // Set the class based on the rate
    //           // _this.paidClass = _this.conversionRate(_this.lastMonthsPaid)
    //           _this.organicClass = _this.conversionRate(_this.lastMonthsOrganic)
              
    //         });
    //       })
    //       .catch((err) => console.error('error calulating time since update:', err));
    //     }
    // },

      

}