// setGoogleDatafunc.js
import axios from 'axios';
import store from '../../src/store';
export async function setGoogleData(data, partial = false, clientSlug = '') {
    try {
      const params = new URLSearchParams();
  
      if (clientSlug) {
        params.append('clientSlug', clientSlug);
      } else {
        params.append('clientSlug', this.getClientSlug);
      }
      if (partial) {
        // If only 1 metric is being updated use the setClientGoogleMetric function
        let theValue = { metricname: partial, data: data };
  
        store.commit('client/setClientGoogleMetric', theValue);
      } else {
        // The whole google is updating so do the whole axois bit.
        params.append('data', data);
  
        await axios.post('/api/clients/googleData/updateGoogleData', params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
  
        console.log('Google Data set');
      }
    } catch (error) {
      console.log('setGoogleData catch error', error);
    }
  }
  