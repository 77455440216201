import axios from 'axios';

export default {
    async uploadMedia(context, data) {
        try {
            context.commit('setHidePageLoader', false);
    
            await Promise.all(data.files.map(mediaItem => {
                // Check if the file size is less than or equal to 100MB
                if(mediaItem.size <= 100 * 1024 * 1024) {
                    const formData = new FormData();
                    formData.append('media', mediaItem);
                    formData.append('clientSlug', data.slug);
                    return axios.post('/api/clients/contentTree/uploadBlogContentMedia', formData, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'clientSlug': data.slug
                        },
                    });
                } else {
                    alert(`File ${mediaItem.name} is larger than 100MB and will not be uploaded.`);
                }
            }));
    
            context.commit('setReloadMediaList');
            context.commit('setSelectedMediaMediaModal', []);
            context.commit('setHidePageLoader', true);
    
        } catch(err) {
            alert('Error uploading media');
            console.log('tools/uploadMedia action error response:', err);
        }
    },      
    async deleteMedia(context) {

        try{

            context.commit('setHidePageLoader', false);

            console.log('DELETE-MEDIA: ', context.getters.getClientSlug);
            let media = JSON.stringify(context.getters.getSelectedMediaMediaModal);
            let slug = JSON.stringify(context.getters.getClientSlug);

            console.log('deleteMedia array:', media);

            const params = new URLSearchParams();

            params.append('media', media);
            params.append('slug', slug);

            const response = await axios.post('/api/clients/deleteMedia', params, {
                                headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                                },
                            })

            
            context.commit('setReloadMediaList');
            context.commit('setSelectedMediaMediaModal', []);
            //DELTE IT IF STILL NOT SAVED IN FEATURED. 
            console.log('deleteMedia successful', response.data);
            context.commit('setHidePageLoader', true);
    
        }catch(err){
            console.log('tools/deleteMedia action error response:', err);
        }
    },
    async setNotification(context, data) {
        context.commit('setNotification', data);
		setTimeout(() => {
            context.commit('setNotification', { text: '', type: ''});
		}, 4000);
    },
}