import axios from 'axios';

import {conversionRate} from '../../../../../mixins/conversionRateFunc';
import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {

    async facebookImpressionsBlockInit(context){

        try{

            // API urls
            
            
            const metricsURL = `/api/google/metric?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&analytics=v4&metrics=`;
            const metricClean = 'sessionSource';
            const metricBr = `${metricClean}`;
            let createNewMetric = false;
            let updatedGoogleData;

                // Work out the time since the last update
                let timeNow = Date.now();

                // If this is the first time it's been loaded there's nothing in the database so:
                // 1. set the time higher to ensure the API data is collected
                let timeUpdated = dayMilliseconds + 1;

                if(context.getters.getClientGoogleData.metrics){
                    if (context.getters.getClientGoogleData.metrics[metricClean]) {
                    // 2. If the data does exist replace the time updated & set the initial variables from the DB
                    timeUpdated = context.getters.getClientGoogleData.metrics[metricClean].updated;
                    context.commit('setComponents', {prop: 'paidSocial/FacebookImpressionsBlock/thisMonthsPaid', val: context.getters.getClientGoogleData.metrics[metricClean].current});
                    if(context.getters.getClientGoogleData.metrics[metricClean].current === 0 && context.getters.getClientGoogleData.metrics[metricClean].previous === 0){
                        context.commit('setComponents', {prop: 'paidSocial/FacebookImpressionsBlock/lastMonthsPaid', val: 0});
                    }else{
                        const data = (((parseFloat(context.getters.getClientGoogleData.metrics[metricClean].current) / parseFloat(context.getters.getClientGoogleData.metrics[metricClean].previous)) * 100) - 100).toFixed(1)
                        context.commit('setComponents', {prop: 'paidSocial/FacebookImpressionsBlock/lastMonthsPaid', val: data});
                    }
                    // Set the class based on the rate
                    context.commit('setComponents', {prop: 'paidSocial/FacebookImpressionsBlock/paidClass', val: conversionRate(context.getters.getComponents.paidSocial.FacebookImpressionsBlock.lastMonthsPaid)});
                    } else {
                        // And if it doesn't add a placeholder for the new metric
                        createNewMetric = true
                    }
                }else {
                    // And if it doesn't add a placeholder for the new metric
                    createNewMetric = true
                }

                let timeSince = timeNow - timeUpdated;

                console.log('==== facebook impression starting ====');
                // If the data hasn't been updated for over a day update from the API
                if (timeSince > dayMilliseconds) {
                    console.log('==== facebook impression starting TIME ====');
                    const response = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`)
                    
                    /*
                    * The data is over a day old, so update from the API and save the updated information to the database.
                    */
                    updatedGoogleData = response.data;
                    if (createNewMetric) {
                        updatedGoogleData.metrics[metricClean] = {}
                    }
                    /*
                    * connect to the API and get the data for the Facebook impressions this month, then compare with last month.
                    */
                    let currentBr = `${metricBr}&startDate=28daysAgo&endDate=1daysAgo`;
                    const response1 = await axios.get(metricsURL + currentBr);
                    const data1 = response1.data.data.rows;
                    const PaidSocialObj = data1.find(item => item.dimensionValues[2].value === 'Paid Social' && item.dimensionValues[1].value === 'facebook.com');
                    if(PaidSocialObj){
                        const total_users = Number(PaidSocialObj.metrics[0].values[0]);	
                        const cohortPageviewsPerUser = Number(PaidSocialObj.metrics[0].values[3]) / Number(PaidSocialObj.metrics[0].values[2]); //ga:cohortPageviewsPerUser  = screenPageViews / cohortActiveUsers
                        updatedGoogleData.metrics[metricClean].current = total_users * cohortPageviewsPerUser; 
                    }else{
                        updatedGoogleData.metrics[metricClean].current = 0;
                    }

                    context.commit('setComponents', {prop: 'paidSocial/FacebookImpressionsBlock/thisMonthsPaid', val: updatedGoogleData.metrics[metricClean].current});

                    // Then compare to previous 30 days
                    let prevBr = `${metricBr}&startDate=56daysAgo&endDate=29daysAgo`;
                    const response2 = await axios.get(metricsURL + prevBr);
                    const data2 = response2.data.data.rows;

                    const PaidSocialObj1 = data2.find(item => item.dimensionValues[2].value === 'Paid Social' && item.dimensionValues[1].value === 'facebook.com');

                    if(PaidSocialObj1){
                        const total_users = Number(PaidSocialObj1.metrics[0].values[1]);	
                        const cohortPageviewsPerUser = Number(PaidSocialObj1.metrics[0].values[4]);
                        updatedGoogleData.metrics[metricClean].previous = total_users * cohortPageviewsPerUser;
                    }else{
                        updatedGoogleData.metrics[metricClean].previous = 0;
                    }

                                            
                    if(context.getters.getComponents.paidSocial.FacebookImpressionsBlock.thisMonthsPaid > 0){

                        const data = (((parseFloat(context.getters.getComponents.paidSocial.FacebookImpressionsBlock.thisMonthsPaid) / updatedGoogleData.metrics[metricClean].previous) * 100) - 100).toFixed(1)
                        context.commit('setComponents', {prop: 'paidSocial/FacebookImpressionsBlock/lastMonthsPaid', val: data});
                
                    }else{
                        context.commit('setComponents', {prop: 'paidSocial/FacebookImpressionsBlock/lastMonthsPaid', val: 0});
                    }
        
                    updatedGoogleData.metrics[metricClean].updated = Date.now();

                    await setGoogleData(updatedGoogleData.metrics[metricClean], metricClean, context.getters.getClientSlug)

                    // Set the class based on the rate
                    context.commit('setComponents', {prop: 'paidSocial/FacebookImpressionsBlock/paidClass', val: conversionRate(context.getters.getComponents.paidSocial.FacebookImpressionsBlock.lastMonthsPaid)});
                
                    
                        
                }



            let newVal = {
                vue: 'paidSocialComponents',
                component: 'FacebookImpressionsBlock',
                val: true
            }
    
            context.commit('setPageLoaded', newVal);


        }catch(error){
			console.log('facebookImpressionsBlockInit error catch', error);
		}
    },
}