import axios from 'axios';
const dayMilliseconds = 86400000;

import {conversionRate} from '../../../../../mixins/conversionRateFunc';
import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";

function secondsToTime(e){
  const h = Math.floor(e / 3600).toString().padStart(2,'0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
        s = Math.floor(e % 60).toString().padStart(2,'0');
  
  return h + ':' + m + ':' + s;
}

export default {

    async analyticsAverageSessionDurationBlockInit(context){
        try{

            // API urls
          
            const metricsURL = `/api/google/metric?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&metrics=`;
            const metricClean = 'averageSessionDuration';
            const metricAsd = `${metricClean}`;
            let createNewMetric = false;
            let updatedGoogleData;
    
            // Work out the time since the last update
            let timeNow = Date.now();
            // If this is the first time it's been loaded there's nothing in the database so:
            // 1. set the time higher to ensure the API data is collected
            let timeUpdated = dayMilliseconds + 1;
            if (context.getters.getClientGoogleData.metrics[metricClean]) {
              // 2. If the data does exist replace the time updated & set the initial variables from the DB
              timeUpdated = context.getters.getClientGoogleData.metrics[metricClean].updated;
              context.commit('setComponents', {prop: 'analytics/AverageSessionDurationBlock/avgSessionVal', val: secondsToTime(context.getters.getClientGoogleData.metrics[metricClean].current)})
              context.commit('setComponents', {prop: 'analytics/AverageSessionDurationBlock/avgSessionComparison', val: (((parseFloat(context.getters.getClientGoogleData.metrics[metricClean].current) / parseFloat(context.getters.getClientGoogleData.metrics[metricClean].previous)) * 100) - 100).toFixed(1)});
              // Set the class based on the rate
              context.commit('setComponents', {prop: 'analytics/AverageSessionDurationBlock/avgSessionClass', val: conversionRate(context.getters.getComponents.analytics.AverageSessionDurationBlock.avgSessionComparison)})
            } else {
              // And if it doesn't add a placeholder for the new metric
              createNewMetric = true
            }

            let timeSince = timeNow - timeUpdated;
    
            // If the data hasn't been updated for over a day update from the API
            if (timeSince > dayMilliseconds) {

              const response = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`);

              /*
               * The data is over a day old - or not set - so update from the API and save the updated information to the database.
               */
              console.log('updated ASD over a day ago');
              updatedGoogleData = response.data;
              if (createNewMetric) {
                updatedGoogleData.metrics[metricClean] = {}
              }
              /*
               * connect to the API and get the data for the Average session duration this month, then compare with last month.
               */
              let currentAsdQS = `${metricAsd}&startDate=28daysAgo&endDate=1daysAgo`;
              const res = await axios.get(metricsURL + currentAsdQS);
              const data = Number(res.data.data.rows[0].metricValues[0].value);

              context.commit('setComponents', {prop: 'analytics/AverageSessionDurationBlock/avgSessionVal', val: secondsToTime(data)});
              updatedGoogleData.metrics[metricClean].current = data
              // Then compare to previous 30 days
              let prevAsdQS = `${metricAsd}&startDate=56daysAgo&endDate=29daysAgo`;
              const res2 = await axios.get(metricsURL + prevAsdQS);
              const data2 = Number(res2.data.data.rows[0].metricValues[0].value);
              
              context.commit('setComponents', {prop: 'analytics/AverageSessionDurationBlock/avgSessionComparison', val: (((parseFloat(data) / data2) * 100) - 100).toFixed(1)});
              updatedGoogleData.metrics[metricClean].previous = data2
              updatedGoogleData.metrics[metricClean].updated = Date.now();
              context.commit('setComponents', {prop: 'analytics/AverageSessionDurationBlock/avgSessionClass', val: conversionRate(context.getters.getComponents.analytics.AverageSessionDurationBlock.avgSessionComparison)});
              await setGoogleData(updatedGoogleData.metrics[metricClean], metricClean, context.getters.getClientSlug)
              // Set the class based on the rate
            }

        }catch(error){
            console.log('init error', error);
        }
    }
}