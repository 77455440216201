import axios from 'axios';

import {conversionRate} from '../../../../../mixins/conversionRateFunc';
import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {

    async analyticsHeaderGraphBlockInit(context){
        try{
            
            // API urls
            
            
            const metricsURL = `/api/google/metric?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&metrics=`;
            // Analytics metrics
            const metricClean = 'totalUsers';
            const metricMom = 'momHits';
            const metricDays = 'dailyHits';
            const momDays = 56;

            const response = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`)
            
            let updatedGoogleData = response.data;

            let timeNow = Date.now();
            // If this is the first time it's been loaded there's nothing in the database so:
            // 1. set the time higher to ensure the API data is collected
            let timeUpdated1 = dayMilliseconds + 1;

            if (response.data.metrics[metricMom]) {

                // 2. If the data does exist replace the time updated
                timeUpdated1 = response.data.metrics[metricMom].updated;
                
            } else {

                // And if it doesn't add a placeholder for the new metric
                updatedGoogleData.metrics[metricMom] = {}
            }

            let timeSince = timeNow - timeUpdated1;
    
            if (timeSince > dayMilliseconds) {
        
                /*
                * The data is over a day old, so update from the API and save the updated information to the database.
                */

                let currentQS = `${metricClean}&startDate=28daysAgo&endDate=1daysAgo`;
                let prevQS = `${metricClean}&startDate=56daysAgo&endDate=29daysAgo`;

                const [currentRes, prevRes] = await Promise.all([
                    axios.get(metricsURL + currentQS),
                    axios.get(metricsURL + prevQS)
                ]);
            
                const currentData = currentRes.data.data.rows[0].metricValues[0].value;
                const prevData = prevRes.data.data.rows[0].metricValues[0].value;
            
                context.commit('setComponents', {prop: 'analytics/HeaderGraphBlock/sessionsVal', val: currentData});
                if (updatedGoogleData.metrics[metricMom]) {
                    updatedGoogleData.metrics[metricMom].current = currentData;
                } else {
                    updatedGoogleData.metrics.momHits = {current: currentData};
                }

                context.commit('setComponents', {prop: 'analytics/HeaderGraphBlock/conversionAmount', val: (context.getters.getComponents.analytics.HeaderGraphBlock.sessionsVal - prevData)});
                updatedGoogleData.metrics[metricMom].previous = prevData
                updatedGoogleData.metrics[metricMom].updated = Date.now()
                await setGoogleData(updatedGoogleData.metrics[metricMom], metricMom, context.getters.getClientSlug)
                // Set the class based on the rate
                context.commit('setComponents', {prop: 'analytics/HeaderGraphBlock/conversionClass', val: conversionRate(context.getters.getComponents.analytics.HeaderGraphBlock.conversionAmount)});

            } else {
   
                /*
                * The data is less than a day old, so grab the data from the database.
                */

                context.commit('setComponents', {prop: 'analytics/HeaderGraphBlock/sessionsVal', val: response.data.metrics[metricMom].current});
                context.commit('setComponents', {prop: 'analytics/HeaderGraphBlock/conversionAmount', val: (response.data.metrics[metricMom].current - response.data.metrics[metricMom].previous)});
                
                // Set the class based on the rate
                context.commit('setComponents', {prop: 'analytics/HeaderGraphBlock/conversionClass', val: conversionRate(context.getters.getComponents.analytics.HeaderGraphBlock.conversionAmount)});

            }

      
            /*
            * connect and grab the daily visitors for the last month, to create the graph.
            */
            const response2 = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`)
            
            updatedGoogleData = response2.data;

            let timeNow2 = Date.now();

            // If this is the first time it's been loaded there's nothing in the database so:
            // 1. set the time higher to ensure the API data is collected

            let timeUpdated = dayMilliseconds + 1;

            if (response2.data.metrics[metricDays]) {
                // 2. If the data does exist replace the time updated
                timeUpdated = response2.data.metrics[metricDays].updated;
            } else {
                // And if it doesn't add a placeholder for the new metric
                updatedGoogleData.metrics[metricDays] = {}
            }

            let timeSince2 = timeNow2 - timeUpdated;

            if (timeSince2 > dayMilliseconds) {
        
                /*
                * The data is over a day old, so update from the API and save the updated information to the database.
                */
                console.log('updated 56 day hits over a day ago = luke');

                let promises = [];

                for (let i = momDays; i > 0; i--) {
     
                    let date = new Date();
                    date.setDate(date.getDate() - i);
                    date = date.toISOString().slice(0, 10);

                    let currentQS = `${metricClean}&startDate=${date}&endDate=${date}`;

                    promises.push((async () => {
                        const res = await axios.get(metricsURL + currentQS);
                        const data = res.data.data.rows[0].metricValues[0].value;
                        if(data){
                            let dataPromises = [];
                            updatedGoogleData.metrics[metricDays][date] = data;
                            updatedGoogleData.metrics[metricDays].updated = Date.now();
                            dataPromises.push(setGoogleData(updatedGoogleData.metrics[metricDays], metricDays, context.getters.getClientSlug));
                            try {
                                await Promise.all(dataPromises);
                            } catch (error) {
                                console.error(error);
                            }

                        }
                    })());
                }

                try {
                    await Promise.all(promises);
                } catch (error) {
                    console.error(error);
                }


            }

            return updatedGoogleData;

        }catch(error){

            console.log('analytics error init', error);

        }
        
    },
}