const formatPounds = {
		methods: {
			formatPounds(x) {
				let pounds = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'GBP' })
                x = pounds.format(x)
				return x
			},
		},
}

export default formatPounds;
