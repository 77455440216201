import axios from 'axios';

import {conversionRate} from '../../../../../mixins/conversionRateFunc';
import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {

    async paidSocialClicksBlockInit(context){

		try{
			
                // API urls
                
                
                // const metric = 'facebookPaidEngagement';
                const metricsURL = `/api/google/metric?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&analytics=v4&metrics=`;
                const metricClean = 'firstUserSourceMedium';
                const metricBr = `${metricClean}`;
                let createNewMetric = false;
                let updatedGoogleData;

                // Work out the time since the last update
                let timeNow = Date.now();

                // If this is the first time it's been loaded there's nothing in the database so:
                // 1. set the time higher to ensure the API data is collected
                let timeUpdated = dayMilliseconds + 1;
                if(context.getters.getClientGoogleData.metrics){
                    if (context.getters.getClientGoogleData.metrics[metricClean]) {
                        // 2. If the data does exist replace the time updated & set the initial variables from the DB
                        timeUpdated = context.getters.getClientGoogleData.metrics[metricClean].updated;
                        context.commit('setComponents', {prop: 'paidSocial/PaidSocialClicksBlock/thisMonthsPaid', val: context.getters.getClientGoogleData.metrics[metricClean].current});
                        if(context.getters.getClientGoogleData.metrics[metricClean].current === 0 && context.getters.getClientGoogleData.metrics[metricClean].previous === 0){
                            context.commit('setComponents', {prop: 'paidSocial/PaidSocialClicksBlock/lastMonthsPaid', val: 0});
                        }else{
                            const data = (((parseFloat(context.getters.getClientGoogleData.metrics[metricClean].current) / parseFloat(context.getters.getClientGoogleData.metrics[metricClean].previous)) * 100) - 100).toFixed(1);
                            context.commit('setComponents', {prop: 'paidSocial/PaidSocialClicksBlock/lastMonthsPaid', val: data});
                        }
                        // Set the class based on the rate
                        context.commit('setComponents', {prop: 'paidSocial/PaidSocialClicksBlock/paidClass', val: conversionRate(context.getters.getComponents.paidSocial.PaidSocialClicksBlock.lastMonthsPaid) });
                    } else {
                        // And if it doesn't add a placeholder for the new metric
                        createNewMetric = true
                    }
                }else{
                    // And if it doesn't add a placeholder for the new metric
                    createNewMetric = true
                }

                let timeSince = timeNow - timeUpdated;

                // If the data hasn't been updated for over a day update from the API
                if (timeSince > dayMilliseconds) {

                    const response = await  await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`)
                    /*
                    * The data is over a day old, so update from the API and save the updated information to the database.
                    */
                    updatedGoogleData = response.data;

                    if (createNewMetric) {

                        updatedGoogleData.metrics[metricClean] = {}

                    }
                    /*
                    * connect to the API and get the data for the Facebook impressions this month, then compare with last month.
                    */
                    let currentBr = `${metricBr}&startDate=28daysAgo&endDate=1daysAgo`;
                    const res = await axios.get(metricsURL + currentBr)
                    const data = res.data.data.rows;
                    const PaidSocialObj = data.find(item => item.dimensionValues[1] === 'facebook.com' && item.dimensionValues[2] === 'Paid Social');

                    updatedGoogleData.metrics[metricClean].current = 0;

                    if(PaidSocialObj){
                        const total_users = Number(PaidSocialObj.metricValues[1].value);
                        const cohortGoalCompletionsPerUser = Number(PaidSocialObj.metricValues[2].value) / Number(PaidSocialObj.metricValues[1].value); //eventCount / cohortActiveUsers
                        updatedGoogleData.metrics[metricClean].current = total_users * cohortGoalCompletionsPerUser
                    }

                    context.commit('setComponents', {prop: 'paidSocial/PaidSocialClicksBlock/thisMonthsPaid', val: updatedGoogleData.metrics[metricClean].current});

                    // Then compare to previous 30 days
                    let prevBr = `${metricBr}&startDate=56daysAgo&endDate=29daysAgo`;
                    const res1 = await axios.get(metricsURL + prevBr)
                    const data1 = res1.data.data.rows;
                    const PaidSocialObj1 = data1.find(item => item.dimensionValues[1] === 'facebook.com' && item.dimensionValues[2] === 'Paid Social');

                    updatedGoogleData.metrics[metricClean].previous = 0;

                    if(PaidSocialObj1){
                        const total_users = Number(PaidSocialObj1.metricValues[1].value);
                        const cohortGoalCompletionsPerUser = Number(PaidSocialObj1.metricValues[2].value) / Number(PaidSocialObj1.metricValues[1].value); //eventCount / cohortActiveUsers
                        updatedGoogleData.metrics[metricClean].previous = total_users * cohortGoalCompletionsPerUser
                    }

                    if(context.getters.getComponents.paidSocial.PaidSocialClicksBlock.thisMonthsPaid > 0){
                        const data = (((parseFloat(context.getters.getComponents.paidSocial.PaidSocialClicksBlock.thisMonthsPaid) / updatedGoogleData.metrics[metricClean].previous) * 100) - 100).toFixed(1)
                        context.commit('setComponents', {prop: 'paidSocial/PaidSocialClicksBlock/lastMonthsPaid', val: data});
                    }else{
                        context.commit('setComponents', {prop: 'paidSocial/PaidSocialClicksBlock/lastMonthsPaid', val: 0});
                    }

                    updatedGoogleData.metrics[metricClean].updated = Date.now()

                    await setGoogleData(updatedGoogleData.metrics[metricClean], metricClean, context.getters.getClientSlug);
                    // Set the class based on the rate
                    context.commit('setComponents', {prop: 'paidSocial/PaidSocialClicksBlock/paidClass', val: conversionRate(context.getters.getComponents.paidSocial.PaidSocialClicksBlock.lastMonthsPaid)});


                }else{
                    console.log('paidSocialClicksBlockInit is under day old');
                }

                const newVal = {
                    vue: 'paidSocialComponents',
                    component: 'PaidSocialClicksBlock',
                    val: true
                }
        
                context.commit('setPageLoaded', newVal);

		}catch(error){
			console.log('paidSocialClicksBlockInit error catch', error);
		}
	},
    
}