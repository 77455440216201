import axios from 'axios';
const dayMilliseconds = 86400000;
import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";

export default {

    async analyticsSessionByChannelBlockInit(context){
        try{
   

            // API urls
            
            
            const metricsURL = `/api/google/metric?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&analytics=v4&metrics=`;
            const metricClean = 'firstUserMedium';
            const metricChannel = `${metricClean}`;
            let createNewMetric = false;
            let updatedGoogleData;
            
            // Work out the time since the last update
            let timeNow = Date.now();
            // If this is the first time it's been loaded there's nothing in the database so:
            // 1. set the time higher to ensure the API data is collected
            let timeUpdated = dayMilliseconds + 1;
            if (context.getters.getClientGoogleData.metrics[metricClean]) {
            // 2. If the data does exist replace the time updated & set the initial variables from the DB
            timeUpdated = context.getters.getClientGoogleData.metrics[metricClean].updated;
            context.commit('setComponents', {prop: 'analytics/SessionByChannelBlock/channelsOrganic', val: context.getters.getClientGoogleData.metrics[metricClean].organic});
            context.commit('setComponents', {prop: 'analytics/SessionByChannelBlock/channelsPaid', val: context.getters.getClientGoogleData.metrics[metricClean].paid});
            context.commit('setComponents', {prop: 'analytics/SessionByChannelBlock/channelsDirect', val: context.getters.getClientGoogleData.metrics[metricClean].direct});
            context.commit('setComponents', {prop: 'analytics/SessionByChannelBlock/channelsReferral', val: context.getters.getClientGoogleData.metrics[metricClean].referral});
            context.commit('setComponents', {prop: 'analytics/SessionByChannelBlock/channelsMax', val: parseInt(context.getters.getComponents.analytics.SessionByChannelBlock.channelsOrganic) + parseInt(context.getters.getComponents.analytics.SessionByChannelBlock.channelsPaid) + parseInt(context.getters.getComponents.analytics.SessionByChannelBlock.channelsDirect) + parseInt(context.getters.getComponents.analytics.SessionByChannelBlock.channelsReferral)});
            } else {
            // And if it doesn't add a placeholder for the new metric
            createNewMetric = true
            }
            let timeSince = timeNow - timeUpdated;
            
            // If the data hasn't been updated for over a day update from the API
            if (timeSince > dayMilliseconds) {

                const response = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`);

                /*
                * The data is over a day old - or not set - so update from the API and save the updated information to the database.
                */
                console.log('updated Channels over a day ago');
                updatedGoogleData = response.data;

                if (createNewMetric) {
                    updatedGoogleData.metrics[metricClean] = {}
                }
                /*
                * connect to the API and get the data for the Average session duration this month, then compare with last month.
                */
                let currentRevenueQS = `${metricChannel}&startDate=28daysAgo&endDate=1daysAgo`;
                
                const res = await axios.get(metricsURL + currentRevenueQS);
                const data = res.data.data.rows;

                // Reset the values
                updatedGoogleData.metrics[metricClean].organic = 0;
                updatedGoogleData.metrics[metricClean].paid = 0;
                updatedGoogleData.metrics[metricClean].direct = 0;
                updatedGoogleData.metrics[metricClean].referral = 0;

                // Loop through and update the values if needed
                for (let i = 0; i < data.length; i++) {
                    let dimension = data[i].dimensionValues[1].value;
                    let value = data[i].metricValues[1].value;
                    if ( dimension === 'organic' ) {
                    updatedGoogleData.metrics[metricClean].organic = value
                    }
                    if ( dimension === 'cpc' ) {
                    updatedGoogleData.metrics[metricClean].paid = value
                    }
                    if ( dimension === '(none)' ) {
                    updatedGoogleData.metrics[metricClean].direct = value
                    }
                    if ( dimension === 'referral' ) {
                    updatedGoogleData.metrics[metricClean].referral = value
                    }
                }

                updatedGoogleData.metrics[metricClean].updated = Date.now();

                await setGoogleData(updatedGoogleData.metrics[metricClean], metricClean, context.getters.getClientSlug)
            
                context.commit('setComponents', {prop: 'analytics/SessionByChannelBlock/channelsOrganic', val: updatedGoogleData.metrics[metricClean].organic});
                context.commit('setComponents', {prop: 'analytics/SessionByChannelBlock/channelsPaid', val: updatedGoogleData.metrics[metricClean].paid});
                context.commit('setComponents', {prop: 'analytics/SessionByChannelBlock/channelsDirect', val: updatedGoogleData.metrics[metricClean].direct});
                context.commit('setComponents', {prop: 'analytics/SessionByChannelBlock/channelsReferral', val: updatedGoogleData.metrics[metricClean].referral});
                context.commit('setComponents', {prop: 'analytics/SessionByChannelBlock/channelsMax', val: parseInt(context.getters.getComponents.analytics.SessionByChannelBlock.channelsOrganic) + parseInt(context.getters.getComponents.analytics.SessionByChannelBlock.channelsPaid) + parseInt(context.getters.getComponents.analytics.SessionByChannelBlock.channelsDirect) + parseInt(context.getters.getComponents.analytics.SessionByChannelBlock.channelsReferral)});

            }

        }catch(error){
            console.log('init error', error);
        }
    }
}