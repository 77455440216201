import ppcAllDataBlock from './ppcAllDataBlock';
import ppcConversionBlock from './ppcConversionBlock';
import ppcConversionRateBlock from './ppcConversionRateBlock';
import ppcCostConversionBlock from './ppcCostConversionBlock';
import ppcCostPerClickBlock from './ppcCostPerClickBlock';
import ppcCTRBlock from './ppcCTRBlock';
import ppcTotalCostBlock from './ppcTotalCostBlock';

export default {

    ...ppcAllDataBlock,
    ...ppcConversionBlock,
    ...ppcConversionRateBlock,
    ...ppcCostConversionBlock,
    ...ppcCostPerClickBlock,
    ...ppcCTRBlock,
    ...ppcTotalCostBlock,
    
}