
import axios from 'axios';

export default {
    async nodeApproval(context, payload){
		try{
            const status = payload ? 'Approved' : 'Rejected';
            //this function also updated the status to 'Pending Review' in the database.
			const params = new URLSearchParams();    
			params.append('slug', context.getters.getClientSlug);
            params.append('nodeId', context.getters.getCurrentNode.id);
            params.append('status', status);
			const response = await axios.post('/api/clients/nodeApproval', params, {
				headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				},
			});
            if(response.data === true){
                alert(`The status has been updated`);
            }else{
                alert(`The status could not be updated. Please try again.`);
            }

            console.log('nodeApproval action response', response);

            // //change status of node to awaiting approval and save it. 
            context.commit("setNodeField", {field : "status", fieldGroup : "status", value : status})

		}catch(error){
			console.log('nodeApproval action catch error', error);
		}
	},
}