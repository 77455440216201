import axios from 'axios';

export default {
	async createRole(context) {
		let roleData = JSON.stringify(context.getters.getRoleData);
		// let roleData = JSON.stringify({ role: 'hello' });

		const params = new URLSearchParams();
		params.append('roleData', roleData);

		return axios
			.post('/api/roles/createRole', params, {
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
				},
			})
			.then((response) => {
				console.log('SUCCESS!!', response);
				context.dispatch('clearRoleData');
			})
			.catch((err) => {
				console.log('FAILURE!!', err.response.data.message);
				this.errorMessage = err.response.data.message;
			});
	},

	async updateRole(context) {
		let roleData = JSON.stringify(context.getters.getRoleData);
		// let roleData = JSON.stringify({ role: 'hello' });

		const params = new URLSearchParams();
		params.append('roleData', roleData);

		return axios
			.post(`/api/roles/updateRole/${context.getters.getRoleSlug}`, params, {
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
				},
			})
			.then((response) => {
				context.commit('setRoleData', response.data.data);
				return response.data;
			})
			.catch((err) => {
				return err.response.data;
			});
	},

	async deleteRole(context, roleId) {
		let roleData = JSON.stringify(context.getters.getRoleData);

		const params = new URLSearchParams();
		params.append('roleData', roleData);

		return axios
			.post(`/api/roles/deleteRole/${roleId}`, params, {
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
				},
			})
			.then((response) => {
				alert('Role has been removed');
				console.log('redirecting to: ', window.location.hostname);
				let port = window.location.port ? ':' + window.location.port : '';
				if (window.location.hostname === 'localhost') {
					port = ':8080';
				}
				window.location.href = window.location.protocol + "//" + window.location.hostname + port;
				return response.data;
			})
			.catch((err) => {
				return err.response.data;
			});
	},

	clearRoleData(context) {
		context.commit('clearRoleData');
	},

	async getAllRoles() {
		const response = await axios
			.get('/api/roles/getAllRoles')
			.then((response) => {
				response.data.sort((a, b) => a.rank - b.rank);
				return response.data;
			})
			.catch((err) => {
				console.log('FAILURE!!', err.response.data.message);
				this.errorMessage = err.response.data.message;
			});

		return response;
	},

	async getAllRolesWithLowerRank() {
		const response = await axios
			.get('/api/roles/getRolesWithLowerRank')
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				console.log('FAILURE!!', err.response.data.message);
				this.errorMessage = err.response.data.message;
			});

		return response;
	},

	async fetchRoleData(context, slug) {
		return await axios
			.get(`/api/roles/findRole/${slug}`)
			.then(async (response) => {
				// Commits the role's data to the store
				context.commit('setRoleData', response.data);
			})
			.catch((err) => console.log(err.response));
	},
};
