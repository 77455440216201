import axios from 'axios';

import {conversionRate} from '../../../../../mixins/conversionRateFunc';
import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {

    async SEOorganicSessionsBlock(context){

        try{

             // API urls
            
            
            const metricsURL = `/api/google/metric?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&analytics=v4&metrics=`;
            const metricClean = 'organicSessionsByMonth';
            const metricChannel = `firstUserMedium`;
            const months = 6;
            let dailyHits = []
            let dailyLabels = []
            let runningTotal = 0;
            let runningTotalPrevious = 0;
            // let createNewMetric = false;
            let updatedGoogleData;

            /*
            * connect and grab the daily visitors for the last month, to create the graph.
            */
            const response = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`);

            updatedGoogleData = response.data;

            // let dailyHits = []
            // let dailyLabels = []
            let timeNow = Date.now();
            // If this is the first time it's been loaded there's nothing in the database so:
            // 1. set the time higher to ensure the API data is collected
            let timeUpdated = dayMilliseconds + 1;

            if (response.data.metrics[metricClean]) {
                // 2. If the data does exist replace the time updated
                timeUpdated = response.data.metrics[metricClean].updated;
            } else {
                // And if it doesn't add a placeholder for the new metric
                updatedGoogleData.metrics[metricClean] = {}
                updatedGoogleData.metrics[metricClean].organic = {}
                updatedGoogleData.metrics[metricClean].paid = {}
                updatedGoogleData.metrics[metricClean].direct = {}
                updatedGoogleData.metrics[metricClean].referral = {}
            }

            let timeSince = timeNow - timeUpdated;

            if (timeSince > dayMilliseconds) {

                    /*
                    * The data is over a day old, so update from the API and save the updated information to the database.
                    */
                    console.log('updated 56 day organic sessions over a day ago');

                    for (var i = months; i > 0; i--) {

                        var date = new Date();
                        var lastmonth = new Date(date.getFullYear(), date.getMonth() - i, 1);
                        var thismonth = new Date(date.getFullYear(), date.getMonth() - (i - 1), 1);
                        lastmonth = lastmonth.toISOString().slice(0, 10);
                        thismonth = thismonth.toISOString().slice(0, 10);

                        // [TODO] work out why the odd one or 2 aren't being saved. might be the var needs reassinging on save if they happen at the same time?
                        let currentRevenueQS = `${metricChannel}&startDate=${lastmonth}&endDate=${thismonth}`;
                        
                        const res = await axios.get(metricsURL + currentRevenueQS);
                        const data = res.data.data.rows;

                        if(!data.message){
                            for (let i = 0; i < data.length; i++) {
                                let dimension = data[i].dimensionValues[1].value;
                                let total_users = Number(data[i].metricValues[0].value);
                                let sessions_per_user = Number(data[i].metricValues[4].value) / Number(data[i].metricValues[1].value); //ga:cohortSessionsPerUser = sessions / cohortActiveUsers
                                let sessions_in_cohort = Math.round(total_users * sessions_per_user); 
                                // The date is set here as by the time the axios response has fired the date var above will have changed
                                let dateOfSessions = res.request.responseURL.slice(-10) // the last 10 chars of the url are the end date
                                if ( dimension === 'organic' ) {
                                    updatedGoogleData.metrics[metricClean].organic[dateOfSessions] = sessions_in_cohort
                                }
                                if ( dimension === 'cpc' ) {
                                    updatedGoogleData.metrics[metricClean].paid[dateOfSessions] = sessions_in_cohort
                                }
                                if ( dimension === '(none)' ) {
                                    updatedGoogleData.metrics[metricClean].direct[dateOfSessions] = sessions_in_cohort
                                }
                                if ( dimension === 'referral' ) {
                                    updatedGoogleData.metrics[metricClean].referral[dateOfSessions] = sessions_in_cohort
                                }
                            }

                        }else{
                            alert(`SEO Organic Sessions Block ${data.message}`);
                        }

                    }

                    updatedGoogleData.metrics[metricClean].updated = Date.now();

                    await setGoogleData(updatedGoogleData.metrics[metricClean], metricClean, context.getters.getClientSlug);
            }

            /*
            * The data is less than a day old, so grab the data from the database.
            */
            for (var j = months; j > 0; j--) {
                var savedDate = new Date();
                savedDate = new Date(savedDate.getFullYear(), savedDate.getMonth() - (j - 1), 1);
                let thedate = new Date(savedDate);
                savedDate = savedDate.toISOString().slice(0, 10);
                let currentDaysHits = updatedGoogleData.metrics[metricClean].organic[savedDate]

                // Check that the current hits amount is a number, incase it's not in the list for any reason.
                if (Number.isInteger(currentDaysHits)) {
                    dailyHits.push(currentDaysHits)
                    let mon = thedate.toLocaleString('en-UK', {month: 'short'});
                    let yy = thedate.toISOString().slice(2, 4)
                    dailyLabels.push(mon + ' ' + yy)
                }

                if ( j === 2 ) {
                    runningTotalPrevious = currentDaysHits
                } else if ( j === 1 ) {
                    runningTotal = currentDaysHits
                }
            }

            context.commit('setComponents', {prop: 'seo/OrganicSessionsBlock/dailyHits', val: dailyHits});
            context.commit('setComponents', {prop: 'seo/OrganicSessionsBlock/dailyLabels', val: dailyLabels});

            context.commit('setComponents', {prop: 'seo/OrganicSessionsBlock/totalOrganicSessions', val: runningTotal});
            context.commit('setComponents', {prop: 'seo/OrganicSessionsBlock/totalOrganicSessionsPrevious', val: (((parseFloat(runningTotal) / runningTotalPrevious) * 100) - 100).toFixed(1)});
            context.commit('setComponents', {prop: 'seo/OrganicSessionsBlock/organicSessionsClass', val: conversionRate(context.getters.getComponents.seo.OrganicSessionsBlock.totalOrganicSessionsPrevious)});

        }catch(error){

            console.log('analytics error init', error);

        }
        
    },
}