// import router from '@/router';
export default {
	toggleSidebar(state) {
		//state.sidebarIsOpen = !state.sidebarIsOpen
		const currentState = JSON.parse(localStorage.getItem('sidebarIsOpen'));
		localStorage.setItem('sidebarIsOpen', !currentState);
		state.sidebarIsOpen = JSON.parse(localStorage.getItem('sidebarIsOpen'));
	},
	setSidebar(state) {
		//console.log(JSON.parse(localStorage.getItem("sidebarIsOpen")))
		let sidebarState = JSON.parse(localStorage.getItem('sidebarIsOpen'));
		//console.log(sidebarState)

		if (sidebarState === null) {
			localStorage.setItem('sidebarIsOpen', true);
			state.sidebarIsOpen = JSON.parse(localStorage.getItem('sidebarIsOpen'));
			// alert('null');
		} else {
			//alert(state.sidebarIsOpen)
			if (sidebarState === false) {
				localStorage.setItem('sidebarIsOpen', false);
				state.sidebarIsOpen = false;
			} else {
				localStorage.setItem('sidebarIsOpen', true);
				state.sidebarIsOpen = true;
			}
		}
	},
	openSidebar(state) {
		localStorage.setItem('sidebarIsOpen', true);
		state.sidebarIsOpen = JSON.parse(localStorage.getItem('sidebarIsOpen'));
	},
	closeSidebar(state) {
		localStorage.setItem('sidebarIsOpen', false);
		state.sidebarIsOpen = JSON.parse(localStorage.getItem('sidebarIsOpen'));
	},
};
