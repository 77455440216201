import SEOclicksImpressionsBlock from './SEOclicksImpressionsBlock';
import SEOgoalCompletionsBlock from './SEOgoalCompletionsBlock';
import SEOheaderGraphBlock from './SEOheaderGraphBlock';
import SEOlatestRankingBlock from './SEOlatestRankingBlock';
import SEOorganicSessionsBlock from './SEOorganicSessionsBlock';

export default {

    ...SEOclicksImpressionsBlock,
    ...SEOgoalCompletionsBlock,
    ...SEOheaderGraphBlock,
    ...SEOlatestRankingBlock,
    ...SEOorganicSessionsBlock,
    
}