import paidSocial from './components/paid-social/paidSocial';
import organicSocial from './components/organic-social/organic-social';
import analytics from './components/analytics/analytics';
import seo from './components/seo/seo';
import ppc from './components/ppc/ppc';
const componentActions = {
  ...paidSocial,
  ...organicSocial,
  ...analytics,
  ...seo,
  ...ppc
};
export default componentActions;