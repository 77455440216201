import toolsGetters from './getters.tools';
import toolsMutations from './mutations.tools';
import toolsActions from './actions.tools';

export default {
	namespaced: true,
	state() {
		return {
			infoModal: {
				isOpen: false,
				currentStep: 0,
				steps: []
			},
			mediaModal: {
				isOpen: false,
				selectedMedia: [],
				droppedFiles: [],
				addMedia: false,
				currentComponent: ''
			},
			mediaList:{
				reload: 0
			},
			clientSlug: '',
			notification: {
				text: '',
				type: ''
			},
			loaders: {
				hidePageLoader: false,
			},
		};
	},
	mutations: toolsMutations,
	actions: toolsActions,
	getters: toolsGetters,
};
