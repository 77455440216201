// import router from '@/router';

export default {
	logOut: (state) => {
		state.authData = {};
	},
	setRoute(state, route) {
		state.route = route;
	},
	setAuthData(state, data) {
		state.authData = data;
		return;
	},
	clearAuthData(state) {
		state.authData = {};
	},
	setSessionLoopStarted(state, data){
		state.sessionLoopStarted = data;
	}
};
