const numberWithCommas = {
		methods: {
			numberWithCommas(x) {
				if(x){
					return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				}
			},
		},
}

export default numberWithCommas;
