import axios from 'axios';
const dayMilliseconds = 86400000;
import {setGoogleData} from "../../../../../mixins/setGoogleDataFunc";

export default {

    async analyticsRevenueBlockInit(context){

        try{

            // API urls
            
            console.log('analyticsRevenueBlockInit starting');
            const metricsURL = `/api/google/metric?ga4property=${context.getters.getSocial.tokens.google.GA4PropertyID}&metrics=`;
            const metricClean = 'newUsers';
            const metricRevenue = `${metricClean}`;
            let createNewMetric = false;
            let updatedGoogleData;
    
            // Work out the time since the last update
            let timeNow = Date.now();
            // If this is the first time it's been loaded there's nothing in the database so:
            // 1. set the time higher to ensure the API data is collected
            let timeUpdated = dayMilliseconds + 1;
            if (context.getters.getClientGoogleData.metrics[metricClean]) {
              // 2. If the data does exist replace the time updated & set the initial variables from the DB
              timeUpdated = context.getters.getClientGoogleData.metrics[metricClean].updated;
              context.commit('setComponents', {prop: 'analytics/RevenueBlock/revenue', val: context.getters.getClientGoogleData.metrics[metricClean].current});
            } else {
              // And if it doesn't add a placeholder for the new metric
              createNewMetric = true
            }
            let timeSince = timeNow - timeUpdated;
    
            // If the data hasn't been updated for over a day update from the API
            if (timeSince > dayMilliseconds) {
    
                const response = await axios.get(`/api/clients/googleData/${context.getters.getClientSlug}`);
        
                /*
                    * The data is over a day old - or not set - so update from the API and save the updated information to the database.
                    */
                console.log('updated Revenue over a day ago');

                updatedGoogleData = response.data;

                if (createNewMetric) {

                    updatedGoogleData.metrics[metricClean] = {}
                    
                }

                /*
                * connect to the API and get the data for the Average session duration this month, then compare with last month.
                */
                let currentRevenueQS = `${metricRevenue}&startDate=28daysAgo&endDate=1daysAgo`;
                const res = await axios.get(metricsURL + currentRevenueQS);
                console.log('res.data', res.data.data);
                const data = res.data.data.rows[0].metricValues[0].value;

                context.commit('setComponents', {prop: 'analytics/RevenueBlock/revenue', val: data});
                updatedGoogleData.metrics[metricClean].current = data
                updatedGoogleData.metrics[metricClean].updated = Date.now();
                await setGoogleData(updatedGoogleData.metrics[metricClean], metricClean, context.getters.getClientSlug);
              
            }

        }catch(error){
            console.log('analyticsRevenueBlockInit error catch', error);
        }

    }
}